import React from "react";
import { Formik, Form, Field } from "formik";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { filterData } from "../../globalFunctions";

export const FormAccesoRapidoInt = () => {
  const navigate = useNavigate();

  const dataEmpresas = useSelector((state) => state.Empresas.data);
  const dataEquipos = useSelector((state) => state.Equipos.data);

  return (
    <Container>
      <Formik
        initialValues={{
          em_iden: "",
          eq_nume: "",
          eq_iden: "",
        }}
        onSubmit={(values) => {
          const eqByNume = filterData(dataEquipos, "eq_nume", values.eq_nume)
          if (eqByNume.length !== 0) {
            const { em_iden, pl_iden, zo_iden, se_iden, eq_iden } = eqByNume[0];
            navigate(
              `/Empresa/${em_iden}/Planta/${pl_iden}/Zona/${zo_iden}/Sector/${se_iden}/Equipo/${eq_iden}/Intervenciones`
            );
          } else {
            Swal.fire({
              toast: true,
              position: "top-end",
              icon:"info",
              text:"Equipo no Encontrado",
              showConfirmButton: false,
              timer:"2800"
            })
          }
        }}
      >
        {({ values, handleChange }) => (
          <Form
            style={{ display: "flex", flexDirection: "column", gap: "8px" }}
          >
            <label>Seleccione Empresa:</label>
            <Field
              as="select"
              type="number"
              name="em_iden"
              onChange={handleChange}
              required
            >
              <option></option>
              {dataEmpresas.map((e) => (
                <option key={e.em_iden} value={e.em_iden}>
                  {e.em_name}
                </option>
              ))}
            </Field>

            {values.em_iden && (
              <>
                <label>Numero del Equipo:</label>
                <input
                  type="number"
                  name="eq_nume"
                  onChange={handleChange}
                  placeholder="Numero Equipo"
                  required
                />
              </>
            )}
            <div>
              <button type="submit">Navegar</button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
