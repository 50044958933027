import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "../vGlobal.js";

/* Listar Imagenes */
export const GetImagenes = createAsyncThunk(
  "GetImagenes",
  async () => {
    try {
      const res = await axios.get(`${url}/Api/Imagenes`);
      return res.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

/* Agregar Imagen */
export const AddImagen = createAsyncThunk(
  "AddImagen",
  async (values) => {
    try {
      const res = await axios.post(`${url}/Api/Imagen`, values);
      const data = res.data
      return {img_iden: data.insertId, ...values};
    } catch (error) {
      throw new Error(error);
    }
  }
);

/* Borrar Imagen */
export const DeleteImagen = createAsyncThunk(
  "DeleteImagen",
  async (id) => {
    try {
      await axios.delete(`${url}/Api/Imagen/${id}`);
      return id;
    } catch (error) {
      throw new Error(error);
    }
  }
);
