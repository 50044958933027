import React from "react";
import TieppoLogo from "../../img/TieppoLogo.png";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Link,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: "5px",
    gap: 20,
  },
  infoTextoPrimero: {
    fontSize: 14,
    fontStyle: "italic",
  },
  infoTextoSegundo: {
    fontSize: 12,
    fontStyle: "italic",
  },
  infoTextoTercero: {
    fontSize: 10,
    fontStyle: "italic",
  },
});

export const PdfIntByIdImg = ({ imgsConvertidas, objEq }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header Reporte */}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            padding: 5,
          }}
        >
          <Image style={{ width: "25%" }} src={TieppoLogo} />
          <View style={{ flexDirection: "column" }}>
            <Text style={styles.infoTextoTercero}>Tieppo Climatización</Text>
            <Link style={styles.infoTextoTercero}>
              administracion@tieppoclimatizacion.com.ar
            </Link>
            <Text style={styles.infoTextoTercero}>03464-15537317</Text>
            <Text style={styles.infoTextoTercero}>
              Washington 1924, Casilda Santa Fe
            </Text>
          </View>
        </View>

        {/* PreBody Reporte */}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            border: "1.2px solid #ddd",
            backgroundColor: "#ddd5",
            padding: 7,
          }}
        >
          <View
            style={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={styles.infoTextoSegundo}>Empresa:</Text>
            <Text style={styles.infoTextoSegundo}>{objEq.Equipo.em_iden}</Text>
          </View>
          <View
            style={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={styles.infoTextoSegundo}>Planta:</Text>
            <Text style={styles.infoTextoSegundo}>{objEq.Equipo.pl_iden}</Text>
          </View>
          <View
            style={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={styles.infoTextoSegundo}>Zona:</Text>
            <Text style={styles.infoTextoSegundo}>{objEq.Equipo.zo_iden}</Text>
          </View>
          <View
            style={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={styles.infoTextoSegundo}>Sector:</Text>
            <Text style={styles.infoTextoSegundo}>{objEq.Equipo.se_iden}</Text>
          </View>
          <View
            style={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={styles.infoTextoSegundo}>Equipo Iden:</Text>
            <Text style={styles.infoTextoSegundo}>{objEq.Equipo.eq_nume}</Text>
          </View>
        </View>

        {/* Body Reporte */}
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 10,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: 10,
            }}
          >
            <Text style={styles.infoTextoSegundo}>
              Intervención Realizada el Día:
            </Text>
            <Text style={styles.infoTextoPrimero}>
              {objEq.Intervencion.int_fech}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "15px",
              maxWidth: "75vw",
            }}
          >
            <Text style={styles.infoTextoPrimero}>Detalle Intervención:</Text>
            <Text style={{ textAlign: "center", fontSize: 10 }}>
              {objEq.Intervencion.int_desc}
            </Text>
            <Text style={styles.infoTextoPrimero}>Adicionales:</Text>
            <Text style={styles.infoTextoSegundo}>
              {objEq.Intervencion.int_adic_desc}
            </Text>
          </View>

          <Text style={styles.infoTextoPrimero}>Imágenes:</Text>
          <View
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 5,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {imgsConvertidas.map((e) => (
              <Image key={e} style={{ width: "20%" }} src={{ uri: e }} />
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};
