import { configureStore } from "@reduxjs/toolkit";

import UsuariosReducer from "./Slices/UsuariosSlice";
import EmpresasReducer from "./Slices/EmpresasSlice";
import MarcasReducer from "./Slices/MarcasSlice";
import TipoEquiposReducer from "./Slices/TipoEquiposSlice";
import IntervencionesReducer from "./Slices/IntervencionesSlice";
import EquiposReducer from "./Slices/EquiposSlice";
import HomeReducers from "./Slices/HomeSlice";
import RolesReducers from "./Slices/RolesSlice";
import ZonasReducers from "./Slices/ZonasSlice";
import PlantasReducers from "./Slices/PlantasSlice";
import SectoresReducers from "./Slices/SectoresSlice";
import LoginReducers from "./Slices/LoginSlice";
import ImagenesSlice from "./Slices/ImagenesSlice";

export const Store = configureStore({
  reducer: {
    Empresas: EmpresasReducer,
    Zonas: ZonasReducers,
    Plantas: PlantasReducers,
    Sectores: SectoresReducers,
    Equipos: EquiposReducer,
    Home: HomeReducers,
    Usuarios: UsuariosReducer,
    Roles: RolesReducers,
    Marcas: MarcasReducer,
    TipoEquipos: TipoEquiposReducer,
    Intervenciones: IntervencionesReducer,
    Login: LoginReducers,
    Imagenes: ImagenesSlice,
  },
  devTools: true,
});