import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { TablaMarcas } from "../Components/Tablas";
import { ModalDinamico } from "../Components/Modals";
import { useSelector } from "react-redux";
import { FormAddMarca } from "../Components/Forms_Add";
import { NotFound } from "./NotFound";
import { BtnVolverDinamico } from "../Components/Botones";
import { useNavigate } from "react-router-dom";
import { filterData } from "../globalFunctions";

export const Marcas = () => {
  const dataMarcas = useSelector((state) => state.Marcas.data);
  const [dataInit, setDataInit] = useState([]);
  const [valueSearch, setValueSearch] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    valueSearch === ""
      ? setDataInit(dataMarcas)
      : setDataInit(filterData(dataMarcas, "ma_desc", valueSearch));
  }, [valueSearch, dataMarcas]);

  return (
    <Container style={{ maxWidth: "900px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          marginTop: "7px",
        }}
      >
        <div>
          <BtnVolverDinamico
            click={() => {
              navigate("/Administracion");
            }}
          />
        </div>
        <div style={{textAlign:"center"}}>
          <h3>Marcas</h3>
          <ModalDinamico
            size={"sm"}
            variant={"outline-dark"}
            Body={<FormAddMarca />}
            Titulo="Agregar Marca"
            nameBoton="Agregar Marca"
          />
        </div>
      </div>
      <hr />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <span style={{ fontStyle: "italic" }}>Buscar </span>
        <input
          type="text"
          placeholder="Nombre Marca"
          onChange={(e) => {
            setValueSearch(e.target.value);
          }}
        />
      </div>

      <div>
        {dataInit.length === 0 ? (
          <NotFound textoImagen={"Ninguna Marca Registrada"} />
        ) : (
          <TablaMarcas data={dataInit} />
        )}
      </div>
    </Container>
  );
};
