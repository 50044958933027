import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Loader, Navbar } from "../Components/Principales";
import { useDispatch, useSelector } from "react-redux";
import { GetEmpresas } from "../Api/Empresas.Api";
import { GetEquipos } from "../Api/Equipos.Api";
import { GetMarcas } from "../Api/Marcas.Api";
import { GetPlantas } from "../Api/Plantas.Api";
import { GetSectores } from "../Api/Sectores.Api";
import { GetZonas } from "../Api/Zonas.Api";
import { GetTipoEquipos } from "../Api/TipoEquipo.Api";
import { GetIntervenciones } from "../Api/Intervenciones.Api";
import { setAccesoBtn } from "../Store/Slices/LoginSlice";
import { Footer } from "../Components/Principales";
import { Container } from "react-bootstrap";
import { GetImagenes } from "../Api/Imagenes.Api";

export const Home = () => {
  const loading = useSelector((state) => state.Home.loading);
  const currentRol = useSelector((state) => state.Login.loginRol);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetEmpresas());
    dispatch(GetPlantas());
    dispatch(GetZonas());
    dispatch(GetSectores());
    dispatch(GetEquipos());
    dispatch(GetMarcas());
    dispatch(GetTipoEquipos());
    dispatch(GetIntervenciones());
    dispatch(GetImagenes());

    switch (currentRol) {
      case 1:
      case 2:
      case 3:
        dispatch(setAccesoBtn(""));
        break;
      default:
        dispatch(setAccesoBtn("Disabled"));
        break;
    }
  }, [currentRol, dispatch]);

  return loading ? (
    <Loader />
  ) : (
    <Container
      style={{
        maxWidth: "900px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Navbar />
      <div style={{ marginTop: "30px", textAlign: "center" }}>
        <span
          style={{
            display: "block",
            color: "#e11414",
            fontSize: "1.2rem",
            marginBottom: "10px",
          }}
        >
          Importante !!!.
        </span>
        <b style={{ display: "block" }}>Recomendaciones de Uso:</b>
        <div
          style={{
            maxWidth: "100vw",
            display: "flex",
            flexDirection: "column",
            gap: "11px",
          }}
        >
          <div>
            <span>
              <FontAwesomeIcon color="#46be6a" icon={faCheck} /> - La App
              recordará la credenciales de acceso, recuerda{" "}
              <b>Cerrar Session.!</b> si no necesitas esta función.
            </span>
          </div>
          <div>
            <span>
              <FontAwesomeIcon color="#46be6a" icon={faCheck} /> - Solo los{" "}
              <b>Usuarios Autorizados</b> pueden eliminar reparaciones
            </span>
          </div>
          <div>
            <span>
              <FontAwesomeIcon color="#46be6a" icon={faCheck} /> - Procura
              tomarte tu tiempo para escribir correctamente los datos.
            </span>
          </div>
          <div>
            <span>
              <FontAwesomeIcon color="#46be6a" icon={faCheck} /> - La APP{" "}
              <b>Discrimina entre Mayusculas y minusculas.</b>
            </span>
          </div>
          <div>
            <span>
              <FontAwesomeIcon color="#46be6a" icon={faCheck} /> - Puedes
              establecer como buena práctica:{" "}
              <b>No escribir todo con Mayusculas</b>
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
};
