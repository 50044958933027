import { createSlice} from "@reduxjs/toolkit";
import { GetStadoServer } from "../../Api/GetStadoServer.Api";

export const HomeSlice = createSlice({
  name: "Home",
  initialState: {
    loading: true,
    error: null,
  },
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      /* Listar Clientes */
      .addCase(GetStadoServer.pending, (state, action) => {
        state.error = action.payload;
        state.loading = true;
      })
      .addCase(GetStadoServer.fulfilled, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(GetStadoServer.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
  },
  
});

export default HomeSlice.reducer;
