import { createSlice } from "@reduxjs/toolkit";
import { GetRoles, DeleteRol } from "../../Api/Roles.Api";

export const RolesSlice = createSlice({
  name: "Roles",
  initialState: {
    data: [],
    error: "",
  },
  reducers: {

   },
  extraReducers:(builder)=>{
    builder
    /* Listar Roles */
    .addCase(GetRoles.pending, (state, action)=>{
      state.error = action.payload;
    })
    .addCase(GetRoles.fulfilled, (state, action)=>{
      state.data = action.payload
    })
    .addCase(GetRoles.rejected, (state, action)=>{
      state.error = action.error
    })
    /* Delete Rol */
    .addCase(DeleteRol.pending, (state, action)=>{
      state.error = action.payload;
    })
    .addCase(DeleteRol.fulfilled, (state, action)=>{
      state.data = state.data.filter(e => e.ro_iden !== action.payload);
    })
    .addCase(DeleteRol.rejected, (state, action)=>{
      state.error = action.error
    })
  }
});

export default RolesSlice.reducer;