import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { TablaUsuarios } from "../Components/Tablas";
import { ModalDinamico } from "../Components/Modals";
import { useSelector } from "react-redux";
import { FormAddUsuario } from "../Components/Forms_Add";
import { NotFound } from "./NotFound";
import { BtnVolverDinamico } from "../Components/Botones";
import { useNavigate } from "react-router-dom";
import { filterData, findName } from "../globalFunctions";

export const Usuarios = () => {
  const dataUsuarios = useSelector((state) => state.Usuarios.data);
  const dataRoles = useSelector((state) => state.Roles.data);
  const [dataInit, setDataInit] = useState([]);
  const [valueSearch, setValueSearch] = useState("");
  const navigate = useNavigate();

  const getdataInit = (arrayUs, arrayRo) =>{
     return arrayUs.map((e) => ({
       UsuarioIden: e.us_iden,
       UsuarioName: findName(arrayUs, "us_iden", e.us_iden, "us_name"),
       UsuarioRol: findName(arrayRo, "ro_iden", e.ro_iden, "ro_desc"),
     }));
  }

  useEffect(() => {
    valueSearch === ""
      ? setDataInit(getdataInit(dataUsuarios, dataRoles))
      : setDataInit(filterData(getdataInit(), "UsuarioName", valueSearch));
  }, [valueSearch, dataUsuarios, dataRoles]);

  return (
    <Container style={{ maxWidth: "900px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          marginTop:"7px"
        }}
      >
        <div>
          <BtnVolverDinamico
            click={() => {
              navigate("/Administracion");
            }}
          />
        </div>
        <div style={{textAlign:"center"}}>
          <h3>Usuarios</h3>

          <ModalDinamico
            size={"sm"}
            variant={"outline-dark"}
            Body={<FormAddUsuario />}
            Titulo="Agregar Usuario"
            nameBoton="Agregar Usuario"
          />
        </div>
      </div>
      <hr />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <span style={{ fontStyle: "italic" }}>Buscar </span>
        <input
          type="text"
          placeholder="Nombre Usuario"
          onChange={(e) => {
            setValueSearch(e.target.value);
          }}
        />
      </div>

      <div>
        {dataInit.length === 0 ? (
          <NotFound textoImagen={"Ningun Usuario Registrado"} />
        ) : (
          <TablaUsuarios data={dataInit} />
        )}
      </div>
    </Container>
  );
};
