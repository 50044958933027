import React, { useState } from "react";
import { Table, Container, Pagination } from "react-bootstrap";
import { BtnBorrarDinamico, BtnBoostrapDinamico } from "../Botones";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { DeleteIntervencion } from "../../Api/Intervenciones.Api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { findName } from "../../globalFunctions";

export const TablaIntervenciones = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const btnAcceso = useSelector((state) => state.Login.btnAcceso);
  const dataUsuarios = useSelector((state) => state.Usuarios.data);

  const PAGE_SIZE = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * PAGE_SIZE;
  const indexOfFirstItem = indexOfLastItem - PAGE_SIZE;
  const currentData = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "800px",
        overflowY: "auto",
        marginTop: "20px",
      }}
    >
      <Table>
        <thead
          style={{
            backgroundColor: "#fed8",
            fontStyle: "italic",
            fontWeight: "bold",
            fontSize: ".8rem",
          }}
        >
          <tr>
            <td>Fecha</td>
            <td>Usuario</td>
            <td>Detalles</td>
            <td>Borrar</td>
          </tr>
        </thead>
        <tbody>
          {currentData.map((e) => (
            <React.Fragment key={e.int_iden}>
              <tr>
                <td style={{ fontSize: ".7rem" }}>
                  <span>{e.int_fech}</span>
                </td>
                <td style={{ fontSize: ".8rem" }}>
                  {findName(dataUsuarios, "us_iden", e.us_iden, "us_name")}
                </td>
                <td>
                  <BtnBoostrapDinamico
                    size={"sm"}
                    name={<FontAwesomeIcon icon={faEye} />}
                    click={() =>
                      navigate(
                        `/Equipo/${e.eq_iden}/Intervencion/${e.int_iden}`
                      )
                    }
                  />
                </td>

                <td>
                  <div>
                    <BtnBorrarDinamico
                      estado={btnAcceso}
                      action={() => dispatch(DeleteIntervencion(e.int_iden))}
                    />
                  </div>
                </td>
              </tr>
              <tr style={{ textAlign: "center" }}>
                <td colSpan={4}>
                  <textarea
                    defaultValue={e.int_desc}
                    readOnly
                    style={{
                      width: "85vw",
                      maxWidth: "700px",
                      fontSize: ".8rem",
                      overflowY: "auto",
                      resize: "none",
                      textAlign: "center",
                      border: "none",
                    }}
                  />
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>

      <Pagination style={{ display: "flex", flexWrap: "wrap" }}>
        {Array.from({ length: Math.ceil(data.length / PAGE_SIZE) }).map(
          (item, index) => (
            <Pagination.Item
              key={index + 1}
              active={index + 1 === currentPage}
              onClick={() => paginate(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          )
        )}
      </Pagination>
    </Container>
  );
};
