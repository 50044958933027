import React from "react";
import TieppoLogo from "../../img/TieppoLogo.png";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Link,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: "5px",
    gap: 20,
  },
  infoTextoPrimero: {
    fontSize: 14,
    fontStyle: "italic",
  },
  infoTextoSegundo: {
    fontSize: 12,
    fontStyle: "italic",
  },
  infoTextoTercero: {
    fontSize: 10,
    fontStyle: "italic",
  },
  EncabezadoInfoEquipo: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#DDD",
    justifyContent: "space-around",
    fontSize: 8,
    borderTop: "1px solid #999",
    padding: "5px",
  },
  BodyInfoEquipo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderBottom: "1px solid #999",
  },
  DflexColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 4,
  },
});

export const PdfEqMens = ({ intByFilterDate, fechaIni, fechaDest }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header Reporte */}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            padding: 5,
          }}
        >
          <Image style={{ width: "25%" }} src={TieppoLogo} />
          <View style={{ flexDirection: "column" }}>
            <Text style={styles.infoTextoTercero}>Tieppo Climatización</Text>
            <Link style={styles.infoTextoTercero}>
              administracion@tieppoclimatizacion.com.ar
            </Link>
            <Text style={styles.infoTextoTercero}>03464-15537317</Text>
            <Text style={styles.infoTextoTercero}>
              Washington 1924, Casilda Santa Fe
            </Text>
          </View>
        </View>

        {/* PreBody Reporte */}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            border: "1.2px solid #ddd",
            backgroundColor: "#ddd5",
            padding: 7,
          }}
        >
          <View
            style={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={styles.infoTextoSegundo}>Reporte Intervenciones:</Text>
            <Text style={styles.infoTextoSegundo}>{}</Text>
          </View>
          <View
            style={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={styles.infoTextoSegundo}>Fecha Inicio:</Text>
            <Text style={styles.infoTextoSegundo}>{fechaIni}</Text>
          </View>
          <View
            style={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={styles.infoTextoSegundo}>Fecha Destino:</Text>
            <Text style={styles.infoTextoSegundo}>{fechaDest}</Text>
          </View>
        </View>

        {/* Body Reporte */}

        <View>
          {intByFilterDate.map((e) => (
            <View style={{ marginBottom: "10px" }} key={e.IntIden}>
              <View>
                {/* Encabezado Info Equipo */}
                <View key={e.EqNume} style={styles.EncabezadoInfoEquipo}>
                  <View style={styles.DflexColumn}>
                    <Text style={styles.infoTextoSegundo}>Empresa</Text>
                    <Text>{e.Empresa}</Text>
                  </View>
                  <View style={styles.DflexColumn}>
                    <Text style={styles.infoTextoSegundo}>Planta</Text>
                    <Text>{e.Planta}</Text>
                  </View>
                  <View style={styles.DflexColumn}>
                    <Text style={styles.infoTextoSegundo}>Zona</Text>
                    <Text>{e.Zona}</Text>
                  </View>
                  <View style={styles.DflexColumn}>
                    <Text style={styles.infoTextoSegundo}>Sector</Text>
                    <Text>{e.Sector}</Text>
                  </View>
                </View>

                {/* Body Info equipo */}
                <View>
                  <View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        alignItems: "center",
                        padding: "10px",
                      }}
                    >
                      <Text style={styles.infoTextoTercero}>Fecha:</Text>
                      <Text style={styles.infoTextoSegundo}>{e.IntFech}</Text>
                      <Text style={styles.infoTextoTercero}>
                        Ident. Equipo:
                      </Text>
                      <Text style={styles.infoTextoPrimero}>{e.EqNume}</Text>
                      <Text style={styles.infoTextoTercero}>Usuario:</Text>
                      <Text style={styles.infoTextoPrimero}>{e.Usuario}</Text>
                    </View>

                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap:"10px",
                        padding:"10px"
                      }}
                    >
                      <Text style={styles.infoTextoPrimero}>
                        Descripcion de Inervencion
                      </Text>
                      <Text style={styles.infoTextoSegundo}>{e.IntDesc}</Text>
                      <Text style={styles.infoTextoPrimero}>
                        Detalle de Adicionales
                      </Text>
                      <Text style={styles.infoTextoSegundo}>
                        {e.IntAdic}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};
