import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { EditarZona } from "../../Api/Zonas.Api";
import { useNavigate } from "react-router-dom";
import { Loader } from "../Principales";
import { findName } from "../../globalFunctions";

export const FormEditZona = ({ id }) => {
  const [zoById, setZoById] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const dataZonas = useSelector((state) => state.Zonas.data);
  const dataEmpresas = useSelector((state) => state.Empresas.data);
  const dataPlantas = useSelector((state) => state.Plantas.data);

  useEffect(() => {
    const res = dataZonas.filter((e) => e.zo_iden === id);
    setZoById(res[0]);
  }, [id, dataZonas]);

  const { zo_desc, em_iden, pl_iden } = zoById;

  return Object.keys(zoById).length === 0 ? (
    <Loader />
  ) : (
    <Container>
      <Formik
        initialValues={{
          pl_iden: pl_iden,
          em_iden: em_iden,
          zo_desc: zo_desc,
        }}
        onSubmit={(values) => {
          Swal.fire({
            title: "Editar Zona ?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Editar !",
          }).then((result) => {
            if (result.isConfirmed) {
              (async (_) => {
                dispatch(EditarZona({ id, values })).then((res) => {
                  switch (res.meta.requestStatus) {
                    case "fulfilled":
                      Swal.fire({
                        icon: "success",
                        text: "Zona Editada",
                        toast: true,
                        position: "top-end",
                        timer: 2800,
                        showConfirmButton: false,
                      });
                      navigate(`/Empresa/${em_iden}/Planta/${pl_iden}/Zonas`);
                      break;

                    default:
                      Swal.fire({
                        icon: "info",
                        title: "Respuesta del Servidor",
                        text: "Vuelve a Iniciar sessión, si persiste el problema contáctanos",
                      });
                      break;
                  }
                });
              })();
            }
          });
        }}
      >
        {({ handleChange }) => (
          <Form
            style={{ display: "flex", flexDirection: "column", gap: "8px" }}
          >
            <label>Empresa:</label>
            <input
              type="number"
              name="em_iden"
              placeholder={findName(
                dataEmpresas,
                "em_iden",
                em_iden,
                "em_name"
                )}
                readOnly
                />
                <label>Planta:</label>
            <input
              type="number"
              name="pl_iden"
              placeholder={findName(dataPlantas, "pl_iden", pl_iden, "pl_desc")}
              readOnly
            />
            <label>Modifica Descripcion:</label>
            <input
              type="text"
              name="zo_desc"
              placeholder={zo_desc}
              onChange={handleChange}
            />
            <div>
              <button type="submit">Editar</button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
