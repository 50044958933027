import { createSlice } from "@reduxjs/toolkit";
import { GetIntervenciones, AddIntervencion, DeleteIntervencion, EditarIntervencion } from "../../Api/Intervenciones.Api";

export const IntervencionesSlice = createSlice({
  name: "Intervenciones",
  initialState: {
    data: [],
    error: null,
  },
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      /* Listar Intervencnion */
      .addCase(GetIntervenciones.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(GetIntervenciones.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(GetIntervenciones.rejected, (state, action) => {
        state.error = action.error;
      })
      /* Add Intervencnion */
      .addCase(AddIntervencion.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(AddIntervencion.fulfilled, (state, action) => {
        state.data.push(action.payload);
      })
      .addCase(AddIntervencion.rejected, (state, action) => {
        state.error = action.error;
        state.data = [];
      })
      /* Delete Intervencnion */
      .addCase(DeleteIntervencion.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(DeleteIntervencion.fulfilled, (state, action) => {
        state.data = state.data.filter(e => e.int_iden !== action.payload);
      })
      .addCase(DeleteIntervencion.rejected, (state, action) => {
        state.error = action.error;
      })
      /* Editar Intervencnion */
      .addCase(EditarIntervencion.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(EditarIntervencion.fulfilled, (state, action) => {
        const index = state.data.findIndex(e => e.int_iden === action.payload.int_iden)
        if(index !== -1){
          state.data[index] = action.payload
        }
      })
      .addCase(EditarIntervencion.rejected, (state, action) => {
        state.error = action.error;
      })
  },
});

export default IntervencionesSlice.reducer;