import React, { useState } from "react";
import { Container, Table, Pagination } from "react-bootstrap";
import { BtnBorrarDinamico } from "../Botones";
import { ModalDinamico } from "../Modals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FormEditTipoEquipo } from "../Forms_Edit";
import { useDispatch } from "react-redux";
import { DeleteTipoEquipo } from "../../Api/TipoEquipo.Api";

export const TablaTipoEquipo = ({ data }) => {
  const dispatch = useDispatch();

  const PAGE_SIZE = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * PAGE_SIZE;
  const indexOfFirstItem = indexOfLastItem - PAGE_SIZE;
  const currentData = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "800px",
        overflowY: "auto",
        marginTop: "20px",
      }}
    >
      <Table>
        <thead
          style={{
            backgroundColor: "#fed8",
            fontStyle: "italic",
            fontWeight: "bold",
            fontSize: ".8rem",
          }}
        >
          <tr>
            <td>Nombre</td>
            <td>Editar</td>
            <td>Borrar</td>
          </tr>
        </thead>
        <tbody>
          {currentData.map((e) => (
            <tr key={e.te_iden}>
              <td>{e.te_desc}</td>
              <td>
                <ModalDinamico
                  size={"sm"}
                  variant={"outline-success"}
                  Body={<FormEditTipoEquipo id={e.te_iden} />}
                  Titulo="Editar Tipo Equipo"
                  nameBoton={<FontAwesomeIcon size="xs" icon={faPenToSquare} />}
                />
              </td>
              <td>
                <BtnBorrarDinamico
                  action={() => dispatch(DeleteTipoEquipo(e.te_iden))}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Pagination style={{ display: "flex", flexWrap: "wrap" }}>
        {Array.from({ length: Math.ceil(data.length / PAGE_SIZE) }).map(
          (item, index) => (
            <Pagination.Item
              key={index + 1}
              active={index + 1 === currentPage}
              onClick={() => paginate(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          )
        )}
      </Pagination>
    </Container>
  );
};
