import React from "react";
import { Spinner, Container } from "react-bootstrap";

export const Loader = () => {
  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "20px",
        gap: "5px",
      }}
    >
      <Spinner animation="grow" role="status" variant="secondary" />
      <span>Loading...</span>
    </Container>
  );
};
