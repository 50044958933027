import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "../vGlobal.js";

/* Get Estado Server */
export const GetStadoServer = createAsyncThunk("GetEstado", async () => {
  try {
    const res = await axios.get(`${url}/Api/Init`)
    return res.data;
  } catch (error) {
    throw new Error(error);
  }
});