import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "../vGlobal.js";

/* Listar Marcas */
export const GetMarcas = createAsyncThunk(
  "GetMarcas",
  async () => {
    try {
      const res = await axios.get(`${url}/Api/Marcas`);
      return res.data;
    } catch (error) {
      return error;
    }
  }
);

/* Agregar Marca */
export const AddMarca = createAsyncThunk(
  "AddMarca",
  async (values) => {
    try {
      const res = await axios.post(`${url}/Api/Marca`, values);
      const data = res.data
      return { ma_iden: data.insertId, ...values};
    } catch (error) {
      return error;
    }
  }
);

/* Borrar Marca */
export const DeleteMarca = createAsyncThunk(
  "DeleteMarca",
  async (id) => {
    try {
      await axios.delete(`${url}/Api/Marca/${id}`);
      return id;
    } catch (error) {
      return error;
    }
  }
);

/* Editar Marca */
export const EditarMarca = createAsyncThunk(
  "EditarMarca",
  async ({values, id}) => {
    try {
      await axios.put(`${url}/Api/Marca/${id}`, values);
      return {ma_iden: id, ...values};
    } catch (error) {
      return error;
    }
  }
);

