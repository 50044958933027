import { createSlice } from "@reduxjs/toolkit";
import { GetSectores, AddSector, DeleteSector, EditarSector } from "../../Api/Sectores.Api";

export const SectoresSlice = createSlice({
  name: "Sectores",
  initialState: {
    data: [],
    error: null,
  },
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      /* Listar Sector */
      .addCase(GetSectores.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(GetSectores.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(GetSectores.rejected, (state, action) => {
        state.error = action.error;
      })
      /* Add Sector */
      .addCase(AddSector.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(AddSector.fulfilled, (state, action) => {
        state.data.push(action.payload);
      })
      .addCase(AddSector.rejected, (state, action) => {
        state.error = action.error;
        state.data = [];
      })
      /* Delete Sector */
      .addCase(DeleteSector.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(DeleteSector.fulfilled, (state, action) => {
        state.data = state.data.filter(e => e.se_iden !== action.payload);
      })
      .addCase(DeleteSector.rejected, (state, action) => {
        state.error = action.error;
      })
      /* Editar Sector */
      .addCase(EditarSector.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(EditarSector.fulfilled, (state, action) => {
        const index = state.data.findIndex(e => e.se_iden === action.payload.se_iden)
        if(index !== -1){
          state.data[index] = action.payload
        }
      })
      .addCase(EditarSector.rejected, (state, action) => {
        state.error = action.error;
      })
  },
});

export default SectoresSlice.reducer;