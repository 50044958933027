import React from "react";
import { Formik, Form } from "formik";
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { AddZona } from "../../Api/Zonas.Api";
import { useNavigate } from "react-router-dom";

export const FormAddZona = ({ idEm, idPl }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dataPlantas = useSelector((state) => state.Plantas.data);
  const dataEmpresas = useSelector((state) => state.Empresas.data);

  return (
    <Container>
      <Formik
        initialValues={{
          zo_desc: "",
          em_iden: parseInt(idEm),
          pl_iden: parseInt(idPl),
        }}
        onSubmit={(values) => {
          Swal.fire({
            title: "Agregar Zona ?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Agregar!",
          }).then((result) => {
            if (result.isConfirmed) {
              (async (_) => {
                dispatch(AddZona(values)).then((res) => {
                  switch (res.meta.requestStatus) {
                    case "fulfilled":
                      Swal.fire({
                        icon: "success",
                        text: "Zona Editada",
                        toast: true,
                        position: "top-end",
                        timer: 2800,
                        showConfirmButton: false,
                      });
                      navigate(`/Empresa/${idEm}}/Planta/${idPl}}/Zonas`);
                      break;

                    default:
                      Swal.fire({
                        icon: "info",
                        title: "Respuesta del Servidor",
                        text: "Vuelve a Iniciar sessión, si persiste el problema contáctanos",
                      });
                      break;
                  }
                });
              })();
            }
          });
        }}
      >
        {({ handleChange }) => (
          <Form
            style={{ display: "flex", flexDirection: "column", gap: "8px" }}
          >
            <input
              type="text"
              name="em_iden"
              readOnly
              placeholder={(() => {
                const resEm = dataEmpresas.filter(
                  (e) => e.em_iden === parseInt(idEm)
                );
                if (resEm.lenght !== 0) {
                  return resEm[0].em_name;
                } else {
                  return "";
                }
              })()}
            />
            <input
              type="text"
              name="pl_desc"
              readOnly
              placeholder={(() => {
                const resPl = dataPlantas.filter(
                  (e) => e.pl_iden === parseInt(idPl)
                );
                if (resPl.lenght !== 0) {
                  return resPl[0].pl_desc;
                } else {
                  return "";
                }
              })()}
            />

            <label>Descripcion:</label>
            <input
              type="text"
              name="zo_desc"
              placeholder="Nombre Zona"
              onChange={handleChange}
              required
            />
            <div>
              <button type="submit">Agregar</button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
