import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "../vGlobal.js"

/* Listar Zonas */
export const GetZonas = createAsyncThunk(
  "GetZonas",
  async () => {
    try {
      const res = await axios.get(`${url}/Api/Zonas`);
      return res.data;
    } catch (error) {
      return error;
    }
  }
);

/* Agregar Zonas */
export const AddZona = createAsyncThunk(
  "AddZona",
  async (values) => {
    try {
      const res = await axios.post(`${url}/Api/Zona`, values);
      const data = res.data
      return {zo_iden: data.insertId, ...values};
    } catch (error) {
      throw new Error(error);
    }
  }
);

/* Borrar Zonas */
export const DeleteZona = createAsyncThunk(
  "DeleteZona",
  async (id) => {
    try {
      await axios.delete(`${url}/Api/Zona/${id}`);
      return id;
    } catch (error) {
      throw new Error(error);
    }
  }
);

/* Editar Zonas */
export const EditarZona = createAsyncThunk(
  "EditarZona",
  async ({values, id}) => {
    try {
      await axios.put(`${url}/Api/Zona/${id}`, values);
      return {zo_iden: id, ...values};
    } catch (error) {
      throw new Error(error);
    }
  }
);