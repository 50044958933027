import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { EditarPlanta } from "../../Api/Plantas.Api";
import { useNavigate } from "react-router-dom";
import { Loader } from "../Principales";
import { findName } from "../../globalFunctions";

export const FormEditPlanta = ({ id }) => {
  const [plById, setPlById] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const dataPlantas = useSelector((state) => state.Plantas.data);
  const dataEmpresas = useSelector((state) => state.Empresas.data);

  useEffect(() => {
    const res = dataPlantas.filter((e) => e.pl_iden === id);
    setPlById(res[0]);
  }, [id, dataPlantas]);

  const { pl_desc, em_iden } = plById;

  return Object.keys(plById).length === 0 ? (
    <Loader />
  ) : (
    <Container>
      <Formik
        initialValues={{
          em_iden: em_iden,
          pl_desc: pl_desc,
        }}
        onSubmit={(values) => {
          Swal.fire({
            title: "Editar Planta?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Editar!",
          }).then((result) => {
            if (result.isConfirmed) {
              (async (_) => {
                dispatch(EditarPlanta({ id, values })).then((res) => {
                  switch (res.meta.requestStatus) {
                    case "fulfilled":
                      Swal.fire({
                        icon: "success",
                        text: "Planta Editada",
                        toast: true,
                        position: "top-end",
                        timer: 2800,
                        showConfirmButton: false,
                      });
                      navigate(`/Empresa/${em_iden}/Plantas`);
                      break;
                    default:
                      Swal.fire({
                        icon: "info",
                        title: "Respuesta del Servidor",
                        text: "Vuelve a Iniciar sessión, si persiste el problema contáctanos",
                      });
                      break;
                  }
                });
              })();
            }
          });
        }}
      >
        {({ handleChange }) => (
          <Form
            style={{ display: "flex", flexDirection: "column", gap: "8px" }}
          >
            <input
              type="number"
              name="em_iden"
              placeholder={findName(dataEmpresas, "em_iden", em_iden, "em_name")}
              readOnly
            />
            <label>Modifica el Nombre: </label>
            <input
              type="text"
              name="pl_desc"
              placeholder={pl_desc}
              onChange={handleChange}
            />
            <div>
              <button type="submit">Editar</button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
