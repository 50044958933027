import { createSlice } from "@reduxjs/toolkit";
import {
  GetUsuarios,
  AddUsuario,
  DeleteUsuario,
  EditarUsuario,
  ValidarCredenciales,
} from "../../Api/Usuarios.Api";

export const UsuariosSlice = createSlice({
  name: "Usuarios",
  initialState: {
    data: [],
    error: "",
    credenciales: "",
  },
  reducers: {
  
  },
  extraReducers: (builder) => {
    builder
      /* Listar usuarios */
      .addCase(GetUsuarios.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(GetUsuarios.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(GetUsuarios.rejected, (state, action) => {
        state.error = action.error;
      })
      /* Agregar Usuario */
      .addCase(AddUsuario.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(AddUsuario.fulfilled, (state, action) => {
        state.data.push(action.payload);
      })
      .addCase(AddUsuario.rejected, (state, action) => {
        state.error = action.error;
        state.data = [];
      })
      /* Delete Usuario */
      .addCase(DeleteUsuario.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(DeleteUsuario.fulfilled, (state, action) => {
        state.data = state.data.filter((e) => e.us_iden !== action.payload);
      })
      .addCase(DeleteUsuario.rejected, (state, action) => {
        state.error = action.error;
      })
      /* Editar Usuario */
      .addCase(EditarUsuario.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(EditarUsuario.fulfilled, (state, action) => {
        const index = state.data.findIndex(
          (e) => e.us_iden === action.payload.us_iden
        );
        if (index !== -1) {
          state.data[index] = action.payload;
        }
      })
      .addCase(EditarUsuario.rejected, (state, action) => {
        state.error = action.error;
      })
      /* Validar Credenciales */
      .addCase(ValidarCredenciales.pending, (state, action) => {})
      .addCase(ValidarCredenciales.fulfilled, (state, action) => {
        state.credenciales = action.payload;
      })
      .addCase(ValidarCredenciales.rejected, (state, action) => {
        state.credenciales = action.error;
      })
  },
});

export default UsuariosSlice.reducer;
