import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { EditarEmpresa } from "../../Api/Empresas.Api";
import { useNavigate } from "react-router-dom";
import { Loader } from "../Principales";

export const FormEditEmpresa = ({ id }) => {
  const [emById, setEmById] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dataEmpresas = useSelector((state) => state.Empresas.data);

  useEffect(() => {
    const res = dataEmpresas.filter((e) => e.em_iden === id);
    setEmById(res[0]);
  }, [id, dataEmpresas]);

  const { em_name, em_email, em_cuit, em_tele } = emById;

  return Object.keys(emById).length === 0 ? (
    <Loader />
  ) : (
    <Container>
      <Formik
        initialValues={{
          em_name: em_name,
          em_email: em_email,
          em_cuit: em_cuit,
          em_tele: em_tele,
        }}
        onSubmit={(values) => {
          Swal.fire({
            title: "Editar Esta Empresa?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Editar !",
          }).then((result) => {
            if (result.isConfirmed) {
              (async (_) => {
                dispatch(EditarEmpresa({ id, values })).then((res) => {
                  switch (res.meta.requestStatus) {
                    case "fulfilled":
                      Swal.fire({
                        icon: "success",
                        text: "Empresa Editada",
                        toast: true,
                        position: "top-end",
                        timer: 2800,
                        showConfirmButton: false,
                      });
                      navigate("/Empresas");
                      break;
                    default:
                      Swal.fire({
                        icon: "info",
                        title: "Respuesta del Servidor",
                        text: "Vuelve a Iniciar sessión, si persiste el problema contáctanos",
                      });
                      break;
                  }
                });
              })();
            }
          });
        }}
      >
        {({ handleChange }) => (
          <Form
            style={{ display: "flex", flexDirection: "column", gap: "8px" }}
          >
            <label>Modifica Nombre:</label>
            <input
              type="text"
              name="em_name"
              placeholder={em_name}
              onChange={handleChange}
            />
            <label>Modifica Email:</label>
            <input
              itype="email"
              name="em_email"
              placeholder={em_email}
              onChange={handleChange}
            />
            <label>Modifica Cuit:</label>
            <input
              type="number"
              name="em_cuit"
              placeholder={em_cuit}
              onChange={handleChange}
            />
            <label>Modifica Telefono:</label>
            <input
              type="number"
              name="em_tele"
              placeholder={em_tele}
              onChange={handleChange}
            />
            <div>
              <button type="submit">Editar</button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
