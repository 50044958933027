import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "../vGlobal.js";

/* Listar Sectores */
export const GetSectores = createAsyncThunk(
  "GetSectores",
  async () => {
    try {
      const res = await axios.get(`${url}/Api/Sectores`);
      return res.data;
    } catch (error) {
      return error;
    }
  }
);

/* Agregar Sector */
export const AddSector = createAsyncThunk(
  "AddSector",
  async (values) => {
    try {
      const res = await axios.post(`${url}/Api/Sector`, values);
      const data = res.data
      return {se_iden: data.insertId, ...values};
    } catch (error) {
      throw new Error(error);
    }
  }
);

/* Borrar Sector */
export const DeleteSector = createAsyncThunk(
  "DeleteSector",
  async (id) => {
    try {
      await axios.delete(`${url}/Api/Sector/${id}`);
      return id;
    } catch (error) {
      throw new Error(error);
    }
  }
);

/* Editar Sector */
export const EditarSector = createAsyncThunk(
  "EditarSector",
  async ({values, id}) => {
    try {
      await axios.put(`${url}/Api/Sector/${id}`, values);
      return {se_iden: id, ...values};
    } catch (error) {
      throw new Error(error);
    }
  }
);