import React, { useRef, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { AddImagen } from "../../Api/Imagenes.Api";
import { crearUrlImagen } from "../../globalFunctions";
import { BtnBoostrapDinamico } from "../Botones";
import camara from "../../img/camara.png";
import { Loader } from "../Principales";

export const BtnSubirFoto = ({ id, estado }) => {
  const [show, setShow] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();
  const imgRef = useRef(null);
  const inputRef = useRef(null);
  const [urlCurrentImg, setUrlCurrentImg] = useState("");

  const OpenFileInput = () => {
    inputRef.current.click();
  };

  const handleInputChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onprogress = function (event) {
      if (reader.readyState === FileReader.LOADING) {
        setIsLoad(true);
      }
    };
    reader.onload = async function (e) {
      await crearUrlImagen(e.target.result, "image/webp").then((res) => {
        setUrlCurrentImg(res);
        imgRef.current.src = res;
      });
    };

    reader.readAsDataURL(file);
  };

  const subirFoto = (urlImgen) => {
    const newImg = {
      img_desc: urlImgen,
      img_int_iden: parseInt(id),
    };
    try {
      dispatch(AddImagen(newImg)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          Swal.fire({
            icon: "success",
            toast: true,
            position: "top-end",
            text: "Imagen Agregada",
            timer: 2800,
            showConfirmButton: false,
          });
        }
      });
    } catch (error) {
      Swal.fire({
        icon: "info",
        title: "Respuesta del Servidor",
        text: "Vuelve a Iniciar sessión, si persiste el problema contáctanos",
      });
      console.log(error);
    } finally {
      setIsLoad(false)
      setShow(false)
      inputRef.current.value = "";
      setUrlCurrentImg("");
    }
  };

  return (
    <>
      <Button
        disabled={estado}
        variant={"outline-danger"}
        size={"sm"}
        onClick={handleShow}
      >
        {<FontAwesomeIcon icon={faUpload} />}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Subir Foto - Intervencion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <BtnBoostrapDinamico name={"Cargar Foto"} click={OpenFileInput} />

            {!isLoad ? (
              <>
                <img style={{ opacity: "50%" }} src={camara} alt="Pre View" />
                <figcaption>Cargar Imagen...</figcaption>
              </>
            ) : urlCurrentImg === "" ? (
              <Loader />
            ) : (
              <div style={{ maxWidth: "290px" }}>
                <img
                  style={{
                    borderRadius: "10px",
                    objectFit: "contain",
                    width: "100%",
                  }}
                  src={urlCurrentImg}
                  alt="Imagen Final"
                />
              </div>
            )}

            <input
              style={{ display: "none" }}
              type="file"
              ref={inputRef}
              onChange={(e) => {
                handleInputChange(e);
              }}
            />

            <div style={{ maxWidth: "290px", display: "none" }}>
              <img
                style={{
                  borderRadius: "10px",
                  objectFit: "contain",
                  width: "100%",
                }}
                ref={imgRef}
                alt="Captura de IMAGEN"
              />
            </div>

            <BtnBoostrapDinamico
              estado={urlCurrentImg === "" ? "disable" : ""}
              name={"Subir Foto"}
              click={() => subirFoto(urlCurrentImg)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
