import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "../vGlobal.js";

/* Listar Roles */
export const GetRoles = createAsyncThunk("GetRoles", async () => {
  try {
    const res = await axios.get(`${url}/Api/Roles`);
    return res.data;
  } catch (error) {
    throw new Error(error)
  }
});

/* Listar Roles */
export const DeleteRol = createAsyncThunk(
  "DeleteRol",
  async (id) => {
    try {
      await axios.delete(`${url}/Api/Rol/${id}`);
      return id;
    } catch (error) {
      throw new Error(error);
    }
  }
);
