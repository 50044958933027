import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { TablaTipoEquipo } from "../Components/Tablas";
import { ModalDinamico } from "../Components/Modals";
import { useSelector } from "react-redux";
import { FormAddTipoEquipo } from "../Components/Forms_Add";
import { NotFound } from "./NotFound";
import { BtnVolverDinamico } from "../Components/Botones";
import { useNavigate } from "react-router-dom";
import { filterData } from "../globalFunctions";

export const TipoEquipo = () => {
  const dataTipoEquipo = useSelector((state) => state.TipoEquipos.data);
  const [dataInit, setDataInit] = useState([]);
  const [valueSearch, setValueSearch] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    valueSearch === ""
      ? setDataInit(dataTipoEquipo)
      : setDataInit(filterData(dataTipoEquipo, "te_desc", valueSearch));
  }, [valueSearch, dataTipoEquipo]);

  return (
    <Container style={{ maxWidth: "900px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          marginTop: "7px",
        }}
      >
        <div>
          <BtnVolverDinamico
            click={() => {
              navigate("/Administracion");
            }}
          />
        </div>
        <div style={{textAlign:"center"}}>
          <h3>Tipo de Equipos</h3>
          <ModalDinamico
            size={"sm"}
            variant={"outline-dark"}
            Body={<FormAddTipoEquipo />}
            Titulo="Agregar Tipo Equipo"
            nameBoton="Agregar Tipo Equipo"
          />
        </div>
      </div>

      <hr />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <span style={{ fontStyle: "italic" }}>Buscar </span>
        <input
          type="text"
          placeholder="Nombre Tipo Equipo"
          onChange={(e) => {
            setValueSearch(e.target.value);
          }}
        />
      </div>

      <div>
        {dataInit.length === 0 ? (
          <NotFound textoImagen={"Ningun Tipo de Equipo Registrado"} />
        ) : (
          <TablaTipoEquipo data={dataInit} />
        )}
      </div>
    </Container>
  );
};
