import { createSlice } from "@reduxjs/toolkit";
import { GetImagenes, AddImagen, DeleteImagen } from "../../Api/Imagenes.Api";

export const ImagenesSlice = createSlice({
  name: "Imagenes",
  initialState: {
    data: [],
    error: ""
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      /* Listar Imagenes */
      .addCase(GetImagenes.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(GetImagenes.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(GetImagenes.rejected, (state, action) => {
        state.error = action.error;
      })
      /* Listar Imagenes */
      .addCase(AddImagen.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(AddImagen.fulfilled, (state, action) => {
        state.data.push(action.payload);
      })
      .addCase(AddImagen.rejected, (state, action) => {
        state.error = action.error;
      })
      /* Delete Imagen */
      .addCase(DeleteImagen.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(DeleteImagen.fulfilled, (state, action) => {
        state.data = state.data.filter(e => e.img_iden !== action.payload);
      })
      .addCase(DeleteImagen.rejected, (state, action) => {
        state.error = action.error;
      })   
  },
});

export default ImagenesSlice.reducer;