import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { TablaEquiposMensuales } from "../Components/Tablas";
import { NotFound } from "./NotFound";
import { BtnBoostrapDinamico } from "../Components/Botones";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PdfEqMens } from "../Components/Forms_Reportes";
import { format } from "date-fns";
import axios from "axios";
import { url } from "../vGlobal";
import { useSelector } from "react-redux";
import { findName } from "../globalFunctions";
import { Loader } from "../Components/Principales";

export const ReporteEquiposMensuales = () => {

  const date = new Date();
  const dateFormat = format(date, "yyyy-MM-dd");
  const [fechaIni, setFechaIni] = useState(dateFormat);
  const [fechaDest, setFechaDest] = useState(dateFormat);
  const [dataInit, setDataInit] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);


  const dataEmpresas = useSelector((state) => state.Empresas.data);
  const dataPlantas = useSelector((state) => state.Plantas.data);
  const dataZonas = useSelector((state) => state.Zonas.data);
  const dataSectores = useSelector((state) => state.Sectores.data);
  const dataEquipos = useSelector((state) => state.Equipos.data);
  const dataUsuarios = useSelector((state) => state.Usuarios.data);

  const getDataInit = async () => {
    try {
      setLoading(true)
      const res = await axios.get(
        `${url}/Api/IntJoinEq/${fechaIni}/${fechaDest}`
      );
      const infoEqPdf = res.data.map((i) => ({
        IntIden: i.int_iden,
        IntDesc: i.int_desc,
        IntAdic: i.int_adic_desc,
        IntFech: i.int_fech,
        EqNume: findName(dataEquipos, "eq_iden", i.eq_iden, "eq_nume"),
        Empresa: findName(dataEmpresas, "em_iden", i.em_iden, "em_name"),
        Planta: findName(dataPlantas, "pl_iden", i.pl_iden, "pl_desc"),
        Zona: findName(dataZonas, "zo_iden", i.zo_iden, "zo_desc"),
        Sector: findName(dataSectores, "se_iden", i.se_iden, "se_desc"),
        Usuario: findName(dataUsuarios, "us_iden", i.us_iden, "us_name"),
      }));

      setDataInit(infoEqPdf);
    } catch (error) {
      setDataInit([]);
      setError(error);
      console.log(error);
    } finally {
      setLoading(false)
    }
  };

  return (
    <Container
      style={{
        maxWidth: "900px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        gap: "1.2em",
      }}
    >
      <div
        style={{
          textAlign: "center",
        }}
      >
        <h1>Reporte Equipos </h1>
        <h3 style={{ opacity: "50%" }}>Rango de Fechas </h3>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "10px",
          gap: "10px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <div>
            <span style={{ fontStyle: "italic", marginRight: "10px" }}>
              Desde:{" "}
            </span>
            <input
              type="date"
              defaultValue={dateFormat}
              onChange={(e) => {
                setFechaIni(e.target.value);
              }}
            />
          </div>
          <div>
            <span style={{ fontStyle: "italic", marginRight: "10px" }}>
              Hasta:{" "}
            </span>
            <input
              type="date"
              defaultValue={dateFormat}
              onChange={(e) => {
                setFechaDest(e.target.value);
              }}
            />
          </div>
          <BtnBoostrapDinamico
            name={"Buscar"}
            click={() => {
              getDataInit();
            }}
            color={"primary"}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <PDFDownloadLink
              document={
                <PdfEqMens
                  intByFilterDate={dataInit}
                  fechaIni={fechaIni}
                  fechaDest={fechaDest}
                />
              }
              fileName={`Equipos Intervenidos ${fechaIni} - ${fechaDest}.pdf`}
            >
              <BtnBoostrapDinamico
                color={"outline-dark"}
                name={<FontAwesomeIcon icon={faDownload} />}
              />
            </PDFDownloadLink>

            <strong>Total: {dataInit.length}</strong>
          </div>
        </div>
      </div>
      <div>
        {loading ? (
          <Loader />
        ) : dataInit.length === 0 ? (
          <NotFound textoImagen={"Ingresa Rango de Fechas"} />
        ) : error ? (
          <NotFound textoImagen={"Problema al Cargar Datos"} />
        ) : (
          <TablaEquiposMensuales data={dataInit} />
        )}
      </div>
    </Container>
  );
};
