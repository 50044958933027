import React from "react";
import { useNavigate } from "react-router";
import { BtnBoostrapDinamico } from "../Botones";
import { Container } from "react-bootstrap";
import { ModalDinamico } from "../Modals";
import { FormAccesoRapidoInt } from "../Forms_Add";
import { useSelector } from "react-redux";
import { FormReporteIntervenciones } from "../../Components/Forms_Reportes";

export const Navbar = () => {
  const navigate = useNavigate();
  const btnAcceso = useSelector((state) => state.Login.btnAcceso);

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "20px",
        gap: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <BtnBoostrapDinamico
        size={"md"}
          estado={btnAcceso}
          name="Empresas"
          click={() => navigate("/Empresas")}
        />
        <BtnBoostrapDinamico
        size={"md"}
          estado={btnAcceso}
          name="Administracion"
          click={() => navigate("/Administracion")}
        />
      </div>
      <div>
        <ModalDinamico
          size={"md"}
          variant={"outline-success"}
          color={"danger"}
          Titulo="Selecciona el Equipo"
          Body={<FormAccesoRapidoInt />}
          nameBoton="Acceso Rapido INT."
        />
      </div>
      <div>
        <BtnBoostrapDinamico
        size={"md"}
          color={"secondary"}
          name="Reporte Intervenciones Mensuales"
          click={() => navigate("/ReporteEquiposMensuales")}
        />
      </div>
    </Container>
  );
};
