import React from "react";
import TieppoLogo from "../../img/TieppoLogo.png";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Link,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: "5px",
    gap: 20,
  },
  infoTextoPrimero: {
    fontSize: 14,
    fontStyle: "italic",
  },
  infoTextoSegundo: {
    fontSize: 12,
    fontStyle: "italic",
  },
  infoTextoTercero: {
    fontSize: 10,
    fontStyle: "italic",
  },
});

export const PdfIntByEq = ({ listaInt, objEq }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header Reporte */}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            padding: 5,
          }}
        >
          <Image style={{ width: "25%" }} src={TieppoLogo} />
          <View style={{ flexDirection: "column" }}>
            <Text style={styles.infoTextoTercero}>Tieppo Climatización</Text>
            <Link style={styles.infoTextoTercero}>
              administracion@tieppoclimatizacion.com.ar
            </Link>
            <Text style={styles.infoTextoTercero}>03464-15537317</Text>
            <Text style={styles.infoTextoTercero}>
              Washington 1924, Casilda Santa Fe
            </Text>
          </View>
        </View>

        {/* PreBody Reporte */}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            border: "1.2px solid #ddd",
            backgroundColor: "#ddd5",
            padding: 7,
          }}
        >
          <View
            style={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={styles.infoTextoSegundo}>Empresa:</Text>
            <Text style={styles.infoTextoSegundo}>{objEq.Empresa}</Text>
          </View>
          <View
            style={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={styles.infoTextoSegundo}>Planta:</Text>
            <Text style={styles.infoTextoSegundo}>{objEq.Planta}</Text>
          </View>
          <View
            style={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={styles.infoTextoSegundo}>Zona:</Text>
            <Text style={styles.infoTextoSegundo}>{objEq.Zona}</Text>
          </View>
          <View
            style={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={styles.infoTextoSegundo}>Sector:</Text>
            <Text style={styles.infoTextoSegundo}>{objEq.Sector}</Text>
          </View>
          <View
            style={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={styles.infoTextoSegundo}>Equipo Iden:</Text>
            <Text style={styles.infoTextoSegundo}>{objEq.EqNume}</Text>
          </View>
        </View>

        {/* Body Reporte */}
        <View
          style={{
            display: "flex",
            flexDirection: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Text>Intervenciones Realizadas:</Text>
          <View style={{ textAlign: "center" }}>
            {listaInt.map((e) => (
              <View
                style={{
                  borderBottom: "1px solid #CCC",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  maxWidth: "80vw",
                }}
                key={e.int_iden}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent:"center",
                    alignItems:"center",
                    gap: "15px",
                  }}
                >
                  <Text style={styles.infoTextoSegundo}>
                    Fecha de la Intervención:
                  </Text>
                  <Text
                    style={{
                      backgroundColor: "#CCC",
                      fontSize: 12,
                      padding: "5px",
                      fontStyle: "italic",
                    }}
                  >
                    {e.int_fech}
                  </Text>
                </View>
                <Text style={styles.infoTextoPrimero}>Detalle</Text>
                <Text style={styles.infoTextoSegundo}>{e.int_desc}</Text>
                <Text style={styles.infoTextoPrimero}>Adicionales</Text>
                <Text style={styles.infoTextoSegundo}>{e.int_adic_desc}</Text>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};
