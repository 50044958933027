import { createSlice } from "@reduxjs/toolkit";
import { GetPlantas, AddPlanta, DeletePlanta, EditarPlanta } from "../../Api/Plantas.Api";

export const PlantasSlice = createSlice({
  name: "Plantas",
  initialState: {
    data: [],
    error: null,
  },
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      /* Listar Planta */
      .addCase(GetPlantas.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(GetPlantas.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(GetPlantas.rejected, (state, action) => {
        state.error = action.error;
      })
      /* Add Planta */
      .addCase(AddPlanta.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(AddPlanta.fulfilled, (state, action) => {
        state.data.push(action.payload);
      })
      .addCase(AddPlanta.rejected, (state, action) => {
        state.error = action.error;
        state.data = [];
      })
      /* Delete Planta */
      .addCase(DeletePlanta.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(DeletePlanta.fulfilled, (state, action) => {
        state.data = state.data.filter(e => e.pl_iden !== action.payload);
      })
      .addCase(DeletePlanta.rejected, (state, action) => {
        state.error = action.error;
      })
      /* Editar Planta */
      .addCase(EditarPlanta.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(EditarPlanta.fulfilled, (state, action) => {
        const index = state.data.findIndex(e => e.pl_iden === action.payload.pl_iden)
        if(index !== -1){
          state.data[index] = action.payload
        }
      })
      .addCase(EditarPlanta.rejected, (state, action) => {
        state.error = action.error;
      })
  },
});

export default PlantasSlice.reducer;