import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { EditarTipoEquipo } from "../../Api/TipoEquipo.Api";
import { useNavigate } from "react-router-dom";
import { Loader } from "../Principales";

export const FormEditTipoEquipo = ({ id }) => {
  const [teById, setTeById] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state.TipoEquipos.data);

  useEffect(() => {
    const res = data.filter((e) => e.te_iden === id);
    setTeById(res[0]);
  }, [id, data]);

  const { te_desc } = teById;

  return Object.keys(teById).length === 0 ? (
    <Loader />
  ) : (
    <Container>
      <Formik
        initialValues={{
          te_desc: te_desc,
        }}
        onSubmit={(values) => {
          Swal.fire({
            title: "Editar Tipo Equipo ?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Editar !",
          }).then((result) => {
            if (result.isConfirmed) {
              (async (_) => {
                dispatch(EditarTipoEquipo({ id, values })).then((res) => {
                  if (res.meta.requestStatus === "fulfilled") {
                    Swal.fire({
                      icon: "success",
                      text: "Tipo Equipo Editado",
                      toast: true,
                      position: "top-end",
                      timer: 2800,
                      showConfirmButton: false,
                    });
                    navigate("/TipoEquipos");
                  } else {
                    Swal.fire({
                      icon: "info",
                      title: "Respuesta del Servidor",
                      text: "Vuelve a Iniciar sessión, si persiste el problema contáctanos",
                    });
                  }
                });
              })();
            }
          });
        }}
      >
        {({ handleChange }) => (
          <Form
            style={{ display: "flex", flexDirection: "column", gap: "8px" }}
          >
            <label>Modifica Descripcion:</label>
            <input
              type="text"
              name="te_desc"
              placeholder={te_desc}
              onChange={handleChange}
            />
            <div>
              <button type="submit">Editar</button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
