import React from "react";
import { Formik, Field, Form } from "formik";
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import { AddEquipo } from "../../Api/Equipos.Api";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

export const FormAddEquipo = ({ idEm, idPl, idZo, idSe }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dataEmpresas = useSelector((state) => state.Empresas.data);
  const dataEquipos = useSelector((state) => state.Equipos.data);
  const dataPlantas = useSelector((state) => state.Plantas.data);
  const dataZonas = useSelector((state) => state.Zonas.data);
  const dataSectores = useSelector((state) => state.Sectores.data);

  const dataTipoEquipo = useSelector((state) => state.TipoEquipos.data);
  const dataMarca = useSelector((state) => state.Marcas.data);

  return (
    <Container>
      <Formik
        initialValues={{
          eq_nume: "",
          eq_frig: "",

          em_iden: idEm,
          pl_iden: idPl,
          zo_iden: idZo,
          se_iden: idSe,

          ma_iden: "",
          te_iden: "",
        }}
        validationSchema={Yup.object({
          eq_nume: Yup.string().test(
            "eqNume",
            "Este numero ya Existe",
            (value) => {
              const res = dataEquipos.findIndex(
                (e) => e.eq_nume === parseInt(value)
              );
              return res === -1;
            }
          ),
        })}
        onSubmit={(values) => {
          const newEquipo = {
            eq_nume: parseInt(values.eq_nume),
            eq_frig: parseInt(values.eq_frig),

            em_iden: parseInt(idEm),
            pl_iden: parseInt(idPl),
            zo_iden: parseInt(idZo),
            se_iden: parseInt(idSe),

            ma_iden: parseInt(values.ma_iden),
            te_iden: parseInt(values.te_iden),
          };
          Swal.fire({
            title: "Agregar Este Equipo?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Agregar!",
          }).then((result) => {
            if (result.isConfirmed) {
              (async (_) => {
                dispatch(AddEquipo(newEquipo)).then((res) => {
                  switch (res.meta.requestStatus) {
                    case "fulfilled":
                      Swal.fire({
                        icon: "success",
                        toast: true,
                        position: "top-end",
                        text: "Equipo Agregado",
                        timer: 2800,
                        showConfirmButton: false,
                      });
                      navigate(
                        `/Empresa/${idEm}/Planta/${idPl}/Zona/${idZo}/Sector/${idSe}/Equipos`
                      );
                      break;
                    default:
                      Swal.fire({
                        icon: "info",
                        title: "Respuesta del Servidor",
                        text: "Vuelve a Iniciar sessión, si persiste el problema contáctanos",
                      });
                      break;
                  }
                });
              })();
            }
          });
        }}
      >
        {({ errors, handleChange }) => (
          <Form
            style={{ display: "flex", flexDirection: "column", gap: "8px" }}
          >
            <label style={{ textAlign: "center" }}>
              Ultimo Equipo Registrado:
              <b style={{ marginLeft: "10px" }}>
                {(() => {
                  const res = dataEquipos[dataEquipos.length - 1];
                  if (res === undefined) {
                    return 0;
                  } else {
                    return res.eq_nume;
                  }
                })()}
              </b>
            </label>

            <input
              type="number"
              name="em_iden"
              readOnly
              placeholder={(() => {
                const res = dataEmpresas.filter(
                  (e) => e.em_iden === parseInt(idEm)
                );
                if (res.length !== 0) {
                  return res[0].em_name;
                } else {
                  return "";
                }
              })()}
            />
            <input
              type="number"
              name="pl_iden"
              readOnly
              placeholder={(() => {
                const res = dataPlantas.filter(
                  (e) => e.pl_iden === parseInt(idPl)
                );
                if (res.length !== 0) {
                  return res[0].pl_desc;
                } else {
                  return "";
                }
              })()}
            />
            <input
              type="number"
              name="zo_iden"
              readOnly
              placeholder={(() => {
                const res = dataZonas.filter(
                  (e) => e.zo_iden === parseInt(idZo)
                );
                if (res.length !== 0) {
                  return res[0].zo_desc;
                } else {
                  return "";
                }
              })()}
            />
            <input
              type="number"
              name="se_iden"
              readOnly
              placeholder={(() => {
                const res = dataSectores.filter(
                  (e) => e.se_iden === parseInt(idSe)
                );
                if (res.length !== 0) {
                  return res[0].se_desc;
                } else {
                  return "";
                }
              })()}
            />
            <label>Seleccione Marca:</label>
            <Field
              as="select"
              type="number"
              name="ma_iden"
              onChange={handleChange}
              required
            >
              <option></option>
              {dataMarca.map((e) => (
                <option key={e.ma_iden} value={e.ma_iden}>
                  {e.ma_desc}
                </option>
              ))}
            </Field>

            <label>Seleccione Tipo Equipo:</label>
            <Field
              as="select"
              type="number"
              name="te_iden"
              onChange={handleChange}
              required
            >
              <option></option>
              {dataTipoEquipo.map((e) => (
                <option key={e.te_iden} value={e.te_iden}>
                  {e.te_desc}
                </option>
              ))}
            </Field>

            <input
              type="number"
              name="eq_nume"
              placeholder="Numero Identificador"
              onChange={handleChange}
              required
            />
            {errors.eq_nume && (
              <div style={{ color: "#e11414" }}>{errors.eq_nume}</div>
            )}
            <input
              type="number"
              name="eq_frig"
              placeholder="Frigorias"
              onChange={handleChange}
              required
            />

            <div>
              <button type="submit">Agregar</button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
