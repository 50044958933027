import React, { useEffect, useState } from "react";
import logo from "../../img/TieppoLogo.png";
import { Link, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { BtnBoostrapDinamico, BtnUpdateDatabase } from "../Botones";
import { useDispatch, useSelector } from "react-redux";
import { setStadoServer, setUser } from "../../Store/Slices/LoginSlice";

export const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [rolInfo, setRolInfo] = useState("");

  const dataRoles = useSelector((state) => state.Roles.data);
  const loginUser = useSelector((state) => state.Login.loginUser);
  const loginRol = useSelector((state) => state.Login.loginRol);

  useEffect(() => {
    const res = dataRoles.filter((e) => e.ro_iden === loginRol);
    setRolInfo(res[0].ro_desc);
  }, [dataRoles, loginRol]);

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "900px",
        padding: "8px",
        borderBottom:"1px solid #DDD"
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Link to={"/"}>
          <img style={{ width: "200px" }} src={logo} alt="Logo Empresa" />
        </Link>
        <span style={{ opacity: "50%" }}>v2.0</span>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          padding:"10px",
          gap:"10px"
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "column", opacity: "75%" }}
        >
          <div>
            <span>
              <b>Usuario En Linea: </b>
            </span>
            <span style={{ color: "#46be6a" }}>{loginUser}</span>
          </div>
          <div>
            <span>
              <b>Rol: </b>
            </span>
            <span style={{ color: "#46be6a" }}>{rolInfo}</span>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
          <BtnBoostrapDinamico
            size={"sm"}
            name="Cerrar Session"
            click={() => {
              dispatch(setStadoServer(false));
              dispatch(setUser(""));
              navigate("/");
              localStorage.removeItem("userStorage");
            }}
            color="secondary"
          />
          <BtnUpdateDatabase />
        </div>
      </div>
    </Container>
  );
};
