import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { TablaEmpresas } from "../Components/Tablas";
import { useSelector } from "react-redux";
import { BtnVolverDinamico } from "../Components/Botones";
import { ModalDinamico } from "../Components/Modals";
import { FormAddEmpresa } from "../Components/Forms_Add";
import { useNavigate } from "react-router-dom";
import { NotFound } from "../Pages/NotFound";
import { filterData } from "../globalFunctions";

export const Empresas = () => {
  const dataEmpresas = useSelector((state) => state.Empresas.data);
  const [valueSearch, setValueSearch] = useState("");
  const [dataInit, setDataInit] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    valueSearch === ""
      ? setDataInit(dataEmpresas)
      : setDataInit(filterData(dataEmpresas, "em_name", valueSearch));
  }, [valueSearch, dataEmpresas]);

  return (
    <Container style={{ maxWidth: "900px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          marginTop:"7px"
        }}
      >
        <div>
          <BtnVolverDinamico
            click={() => {
              navigate("/");
            }}
          />
        </div>
        <div style={{textAlign:"center"}}>
          <h3>Empresas</h3>
          <ModalDinamico
            size={"sm"}
            variant={"outline-dark"}
            Body={<FormAddEmpresa />}
            Titulo="Agregar Empresa"
            nameBoton="Agregar Empresa"
          />
        </div>
      </div>

      <hr />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <span style={{ fontStyle: "italic" }}>Buscar </span>
        <input
          type="text"
          placeholder="Nombre Empresa"
          onChange={(e) => {
            setValueSearch(e.target.value);
          }}
        />
      </div>

      <div>
        {dataInit.length === 0 ? (
          <NotFound textoImagen={"Ninguna Empresa Registrada"} />
        ) : (
          <TablaEmpresas data={dataInit} />
        )}
      </div>
    </Container>
  );
};
