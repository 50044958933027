import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";

export const BtnVolverDinamico = ({ click }) => {
  const currentUser = useSelector((state) => state.Login.btnAcceso);

  return (
    <>
      <Button variant="outline-dark" onClick={click} disabled={currentUser}>
        {<FontAwesomeIcon icon={faArrowLeft} />}
      </Button>
    </>
  );
};
