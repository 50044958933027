import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "../vGlobal.js";

/* Listar Plantas */
export const GetPlantas = createAsyncThunk(
  "GetPlantas",
  async () => {
    try {
      const res = await axios.get(`${url}/Api/Plantas`);
      return res.data;
    } catch (error) {
      throw new Error(error)
    }
  }
);

/* Agregar Planta */
export const AddPlanta = createAsyncThunk(
  "AddPlanta",
  async (values) => {
    try {
      const res = await axios.post(`${url}/Api/Planta`, values);
      const data = res.data
      return {pl_iden: data.insertId, ...values};
    } catch (error) {
      throw new Error(error);
    }
  }
);

/* Borrar Planta */
export const DeletePlanta = createAsyncThunk(
  "DeletePlanta",
  async (id) => {
    try {
      await axios.delete(`${url}/Api/Planta/${id}`);
      return id;
    } catch (error) {
      throw new Error(error);
    }
  }
);

/* Editar Planta */
export const EditarPlanta = createAsyncThunk(
  "EditarPlanta",
  async ({id, values}) => {
    try {
      await axios.put(`${url}/Api/Planta/${id}`, values);
      return {pl_iden: id, ...values};
    } catch (error) {
      throw new Error(error);
    }
  }
);