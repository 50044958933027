import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "../vGlobal.js";

/* Listar Intervenciones */
export const GetIntervenciones = createAsyncThunk(
  "GetIntervenciones",
  async () => {
    try {
      const res = await axios.get(`${url}/Api/Intervenciones`);
      return res.data;
    } catch (error) {
      return error;
    }
  }
);

/* Agregar Intervencion */
export const AddIntervencion = createAsyncThunk(
  "AddIntervencion",
  async (values) => {
    try {
      const res = await axios.post(`${url}/Api/Intervencion`, values);
      const data = res.data
      return {int_iden: data.insertId, ...values};
    } catch (error) {
      throw new Error(error);
    }
  }
);

/* Borrar Intervencion */
export const DeleteIntervencion = createAsyncThunk(
  "DeleteIntervencion",
  async (id) => {
    try {
      await axios.delete(`${url}/Api/Intervencion/${id}`);
      return id;
    } catch (error) {
      throw new Error(error);
    }
  }
);

/* Editar Intervencion */
export const EditarIntervencion = createAsyncThunk(
  "EditarIntervencion",
  async ({values, id }) => {
    try {
      await axios.put(`${url}/Api/Intervencion/${id}`, values);
      return {int_iden: id, ...values};
    } catch (error) {
      throw new Error(error);
    }
  }
);