import React from "react";
import { Button } from "react-bootstrap";

export const BtnBoostrapDinamico = ({ name, click, color, estado, size }) => {
  return (
    <Button disabled={estado} size={size} onClick={click} variant={color}>
      {name}
    </Button>
  );
};
