import { createSlice } from "@reduxjs/toolkit";
import {
  GetMarcas,
  AddMarca,
  DeleteMarca,
  EditarMarca,
} from "../../Api/Marcas.Api";

export const MarcasSlice = createSlice({
  name: "Marcas",
  initialState: {
    data: [],
    error: null,
  },
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      /* Listar Marcas */
      .addCase(GetMarcas.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(GetMarcas.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(GetMarcas.rejected, (state, action) => {
        state.error = action.payload;
      })
      /* Add Marca */
      .addCase(AddMarca.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(AddMarca.fulfilled, (state, action) => {
        state.data.push(action.payload);
      })
      .addCase(AddMarca.rejected, (state, action) => {
        state.error = action.payload;
      })
      /* Delete Marca */
      .addCase(DeleteMarca.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(DeleteMarca.fulfilled, (state, action) => {
        state.data = state.data.filter((e) => e.ma_iden !== action.payload);
      })
      .addCase(DeleteMarca.rejected, (state, action) => {
        state.error = action.payload;
      })
      /* Editar Marca */
      .addCase(EditarMarca.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(EditarMarca.fulfilled, (state, action) => {
        const index = state.data.findIndex(
          (e) => e.ma_iden === action.payload.ma_iden
        );
        if (index !== -1) {
          state.data[index] = action.payload;
        }
      })
      .addCase(EditarMarca.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export default MarcasSlice.reducer;
