import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { AddIntervencion } from "../../Api/Intervenciones.Api";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { Loader } from "../Principales";
import { findName } from "../../globalFunctions";

export const FormAddIntervencion = ({ idEm, idPl, idZo, idSe, idEq }) => {
  const [idUsbyName, setIdUsbyName] = useState("");
  const [date, setDate] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dataUsuarios = useSelector((state) => state.Usuarios.data);
  const dataEquipos = useSelector((state) => state.Equipos.data);
  const currentUser = useSelector((state) => state.Login.loginUser);

  useEffect(() => {
    const res = dataUsuarios.filter((e) => e.us_name === currentUser);
    setIdUsbyName(res[0].us_iden);
    const currentDate = new Date();
    setDate(format(currentDate, "yyyy-MM-dd"));
  }, [dataUsuarios, currentUser]);

  return idUsbyName === 0 || date === "" ? (
    <Loader />
  ) : (
    <Container>
      <Formik
        initialValues={{
          int_fech: date,
          us_iden: idUsbyName,
          eq_iden: parseInt(idEq),
          int_desc: "",
          int_adic_desc: "",
        }}
        onSubmit={(values) => {
          Swal.fire({
            title: "Agregar Intervencion?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Agregar!",
          }).then((result) => {
            if (result.isConfirmed) {
              console.log(values);
              (async (_) => {
                await dispatch(AddIntervencion(values)).then((res) => {
                  switch (res.meta.requestStatus) {
                    case "fulfilled":
                      Swal.fire({
                        icon: "success",
                        toast: true,
                        position: "top-end",
                        text: "intervencion Agregada",
                        timer: 2800,
                        showConfirmButton: false,
                      });

                      navigate(
                        `/Empresa/${idEm}/Planta/${idPl}/Zona/${idZo}/Sector/${idSe}/Equipo/${idEq}/Intervenciones`
                      );
                      break;
                    default:
                      Swal.fire({
                        icon: "info",
                        title: "Respuesta del Servidor",
                        text: "Vuelve a Iniciar sessión, si persiste el problema contáctanos",
                      });
                      break;
                  }
                });
              })();
            }
          });
        }}
      >
        {({ handleChange }) => (
          <Form
            style={{ display: "flex", flexDirection: "column", gap: "8px" }}
          >
            <input type="text" name="int_fech" placeholder={date} readOnly />
            <input
              type="number"
              name="us_iden"
              placeholder={currentUser}
              readOnly
            />
            <label>Identificador Equipo:</label>
            <input
              type="number"
              name="eq_iden"
              placeholder={findName(
                dataEquipos,
                "eq_iden",
                parseInt(idEq),
                "eq_nume"
              )}
              readOnly
            />

            <label>Descripcion:</label>
            <textarea
              type="text"
              name="int_desc"
              placeholder="Ingresa Detalle Intervencion"
              onChange={handleChange}
              required
            />

            <label>Adicionales:</label>
            <textarea
              type="text"
              name="int_adic_desc"
              placeholder="Ingresa Adicionales"
              onChange={handleChange}
            />
            <div>
              <button type="submit">Agregar</button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
