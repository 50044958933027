import { createSlice } from "@reduxjs/toolkit";
import { GetEmpresas, AddEmpresa, DeleteEmpresa, EditarEmpresa } from "../../Api/Empresas.Api";

export const EmpresasSlice = createSlice({
  name: "Empresas",
  initialState: {
    data: [],
    error: ""
  },
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      /* Listar Empresa */
      .addCase(GetEmpresas.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(GetEmpresas.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(GetEmpresas.rejected, (state, action) => {
        state.error = action.error;
      })
      /* Add Empresa */
      .addCase(AddEmpresa.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(AddEmpresa.fulfilled, (state, action) => {
        state.data.push(action.payload);
      })
      .addCase(AddEmpresa.rejected, (state, action) => {
        state.error = action.error;
      })
      /* Delete Empresa */
      .addCase(DeleteEmpresa.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(DeleteEmpresa.fulfilled, (state, action) => {
        state.data = state.data.filter(e => e.em_iden !== action.payload);
      })
      .addCase(DeleteEmpresa.rejected, (state, action) => {
        state.error = action.error;
      })
      /* Editar Empresa */
      .addCase(EditarEmpresa.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(EditarEmpresa.fulfilled, (state, action) => {
        const index = state.data.findIndex(e => e.em_iden === action.payload.em_iden)
        if(index !== -1){
          state.data[index] = action.payload
        }
      })
      .addCase(EditarEmpresa.rejected, (state, action) => {
        state.error = action.error;
      })
  },
});

export default EmpresasSlice.reducer;
