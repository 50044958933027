import React from "react";
import { useNavigate } from "react-router";
import { Container } from "react-bootstrap";
import { BtnBoostrapDinamico } from "../Components/Botones";

export const Administracion = () => {
  const navigate = useNavigate();

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        maxWidth: "320px",
      }}
    >
      <h3>Administracion:</h3>
      <span>Sistema :</span>
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <BtnBoostrapDinamico
          name="Usuarios"
          click={() => navigate("/Usuarios")}
        />

        <span>Extras :</span>
        <BtnBoostrapDinamico
          name="Listado Marcas"
          click={() => navigate("/Marcas")}
        />
        <BtnBoostrapDinamico
          name="Listado Tipo Equipos"
          click={() => navigate("/TipoEquipos")}
        />
      </div>
    </Container>
  );
};
