import React, { useState, useRef, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { AddImagen } from "../../Api/Imagenes.Api";
import Swal from "sweetalert2";
import { BtnBoostrapDinamico } from "../Botones";
import { crearUrlDesdeVideo } from "../../globalFunctions";

export const BtnTomarFoto = ({ id, estado }) => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const webcamRef = useRef(null);
  const imgRef = useRef(null);

  const HabilitarCamara = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: "environment",
        },
        audio: false,
      });
      if (webcamRef.current) {
        webcamRef.current.srcObject = stream;
      }
    } catch (error) {
      Swal.fire({
        icon: "info",
        title: "Error Acceso a Camara",
        text: "Vuelve a Intentarlo, si persiste el problema contáctanos",
      });
      console.error("Error acceso camera:", error);
    }
  };

  const capturePhoto = async () => {
    if (webcamRef.current && imgRef.current) {
      const video = webcamRef.current;
      await crearUrlDesdeVideo(video).then((res) => {
        if (res.length !== 0) {
          imgRef.current.src = res;
          const newImg = {
            img_desc: res,
            img_int_iden: parseInt(id),
          };

          dispatch(AddImagen(newImg)).then((res) => {
            if (res.meta.requestStatus === "fulfilled") {
              Swal.fire({
                icon: "success",
                toast: true,
                position: "top-end",
                text: "Imagen Agregada",
                timer: 2800,
                showConfirmButton: false,
              })
              setShow(false)
            } else {
              Swal.fire({
                icon: "info",
                title: "Respuesta del Servidor",
                text: "Vuelve a Iniciar sessión, si persiste el problema contáctanos",
              });
            }
          });
        } else {
          Swal.fire({
            icon: "info",
            title: "Error Camara",
            text: "Vuelve a Intentarlo, si persiste el problema contáctanos",
          });
        }
      });
    }
  };

  useEffect(() => {
    HabilitarCamara();
  }, [show]);

  return (
    <>
      <Button
        disabled={estado}
        variant={"outline-primary"}
        size={"sm"}
        onClick={handleShow}
      >
        {<FontAwesomeIcon icon={faCamera} />}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Tomar Foto - Intervencion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <video
                style={{ width: "100%", borderRadius: "10px" }}
                ref={webcamRef}
                autoPlay
                playsInline
              />
              <img
                ref={imgRef}
                style={{
                  display:"none"
                }}
                alt="Captura de IMAGEN"
              />

              <div>
                <BtnBoostrapDinamico
                  name={"Capturar Foto"}
                  click={capturePhoto}
                />
              </div>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
