/* Encuentra Propiedad especifica de un objeto en array*/
export const findName = (array, key, value, prop) => {
  const res = array.find((e) => e[key] === value);
  return res ? res[prop] : {};
};

/* Encuentra Objeto en un Array */
export const findObject = (array, key, value) => {
  const res = array.find((e) => e[key] === value);
  return res ? res : {};
};

/* Funcion que retorna array filtrado con los datos de props */
export const filterData = (array, key, value) => {
  const dataFilter = array.filter((e) => e[key] === value);
  return dataFilter.lenght === 0 ? [] : dataFilter;
};

/* Funcion para filtrar por fechas */
export const filterByDate = (array, key, fechaIni, fechaDest) => {
  const dataFilter = array.filter(
    (e) => e[key] >= fechaIni && e[key] <= fechaDest
  );
  return dataFilter.lenght === 0 ? [] : dataFilter;
};

/* Convertir Webp a PNG */
export const crearUrlImagen = (urlWebP, formatoSalida) => {
  return new Promise((res) => {
    const imagen = new Image();
    imagen.onload = function () {
      const canvas = document.createElement("canvas");
      canvas.width = imagen.width;
      canvas.height = imagen.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(imagen, 0, 0);
      const dataPng = canvas.toDataURL(formatoSalida);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      res(dataPng);
    };
    imagen.src = urlWebP;
  });
};

/* Crear Url webp a partir de Video src */
export const crearUrlDesdeVideo = (video) => {
  return new Promise(res=>{
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    const url = canvas.toDataURL("image/webp");
    res(url)
  })
};
