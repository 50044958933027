import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  BtnVolverDinamico,
  BtnBorrarDinamico,
  BtnBoostrapDinamico,
  BtnTomarFoto,
  BtnSubirFoto,
} from "../Components/Botones";
import { DeleteImagen } from "../Api/Imagenes.Api";
import camara from "../img/camara.png";
import { ModalDinamico } from "../Components/Modals";
import { FormEditIntervencion } from "../Components/Forms_Edit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faDownload } from "@fortawesome/free-solid-svg-icons";
import { filterData, findObject, crearUrlImagen } from "../globalFunctions";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PdfIntByIdImg } from "../Components/Forms_Reportes";
import { Loader } from "../Components/Principales";

export const IntervencionDetalle = () => {
  const { idEq, idInt } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [dataInit, setDataInit] = useState({});
  const [imgConvertidas, setImgConvertidas] = useState([]);

  const btnAcceso = useSelector((state) => state.Login.btnAcceso);
  const currentRol = useSelector((state) => state.Login.loginRol);

  const dataEquipos = useSelector((state) => state.Equipos.data);
  const dataIntervenciones = useSelector((state) => state.Intervenciones.data);
  const dataImagenes = useSelector((state) => state.Imagenes.data);

  const procesarImagenesWebP = async (array) => {
    const imagenesPNG = [];
    for (const url of array) {
      const dataPng = await crearUrlImagen(url,"image/png");
      imagenesPNG.push(dataPng);
    }
    return imagenesPNG;
  };

  useEffect(() => {
    const CurrentEq = {
      Equipo: findObject(dataEquipos, "eq_iden", parseInt(idEq)),
      Imagenes: filterData(dataImagenes, "img_int_iden", parseInt(idInt)),
      Intervencion: findObject(dataIntervenciones, "int_iden", parseInt(idInt)),
    };
    setDataInit(CurrentEq);

    const Imagenes = filterData(dataImagenes, "img_int_iden", parseInt(idInt));
    const soloUrlsWebp = Imagenes.map((e) => e.img_desc);
    const ObtenerImagenesConvertidas = async () => {
      await procesarImagenesWebP(soloUrlsWebp).then((res) =>
        setImgConvertidas(res)
      );
    };
    ObtenerImagenesConvertidas();
  }, [
    idEq,
    idInt,
    dataImagenes,
    dataEquipos,
    dataIntervenciones,
  ]);

  return Object.keys(dataInit).length === 0 ? (
    <Loader />
  ) : (
    <Container style={{ maxWidth: "900px" }}>
      <div
        style={{
          textAlign: "center",
          marginBottom: "15px",
          marginTop: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "15px",
        }}
      >
        <h3>Equipo</h3>
        <b style={{ color: "#46be6a", fontSize: "1.5rem" }}>
          {dataInit.Equipo.eq_nume}
        </b>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <BtnVolverDinamico
          click={() =>
            navigate(
              `/Empresa/${dataInit.Equipo.em_iden}/Planta/${dataInit.Equipo.pl_iden}/Zona/${dataInit.Equipo.zo_iden}/Sector/${dataInit.Equipo.se_iden}/Equipo/${idEq}/Intervenciones`
            )
          }
        />
        <div>
          <PDFDownloadLink
            document={
              <PdfIntByIdImg
                imgsConvertidas={imgConvertidas}
                objEq={dataInit}
              />
            }
            fileName={`Reporte Imagenes Equipo ${dataInit.Equipo.eq_nume}.pdf`}
          >
            <BtnBoostrapDinamico
              color={"outline-dark"}
              name={<FontAwesomeIcon icon={faDownload} />}
            />
          </PDFDownloadLink>
        </div>
        <div
          style={{
            display: "flex",
            gap: "20px",
            border: "1px solid #CCC",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          <BtnSubirFoto id={idInt} estado={currentRol !== 5 ? "" : "disable"} />

          <BtnTomarFoto id={idInt} estado={currentRol !== 5 ? "" : "disable"} />
        </div>
        <div>
          <ModalDinamico
            size={"sm"}
            variant={"outline-dark"}
            estado={btnAcceso}
            Body={
              <FormEditIntervencion
                id={dataInit.Intervencion.int_iden}
                idEq={dataInit.Equipo.eq_iden}
              />
            }
            Titulo="Editar Intervencion"
            nameBoton={<FontAwesomeIcon icon={faPenToSquare} />}
          />
        </div>
      </div>
      <hr></hr>
      <Container>
        <div style={{ textAlign: "center" }}>
          <h4 style={{ fontStyle: "italic" }}>Detalle de la Intervencion:</h4>
          <p style={{ fontSize: "1rem" }}>{dataInit.Intervencion.int_desc}</p>
          <h4 style={{ fontStyle: "italic" }}>Adicionales:</h4>
          {dataInit.Intervencion.int_adic_desc === null ? (
            <span>Sin Adicionales</span>
          ) : (
            <p style={{ fontSize: "1rem" }}>
              {dataInit.Intervencion.int_adic_desc}
            </p>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "9px",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "15px",
              flexWrap: "wrap",
            }}
          >
            <h4 style={{ fontStyle: "italic" }}>Imágenes:</h4>
            {dataInit.Imagenes.length === 0 ? (
              <div style={{ textAlign: "center" }}>
                <img
                  style={{ width: "50%", opacity: "50%" }}
                  src={camara}
                  alt="Camara de foto"
                />
              </div>
            ) : (
              <div
                style={{
                  width: "80vw",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                  marginBottom:"15px"
                }}
              >
                {dataInit.Imagenes.map((e) => (
                  <div key={e.img_iden}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "5px",
                        }}
                      >
                        <BtnBoostrapDinamico
                          color={"outline-primary"}
                          name={<FontAwesomeIcon icon={faDownload} />}
                          click={() => {
                            if (e.img_desc) {
                              const time = new Date().getTime();
                              const link = document.createElement("a");
                              link.href = e.img_desc;
                              link.download = `${time}_INT:${e.img_iden}.png`;
                              link.click();
                            }
                          }}
                        />
                        <BtnBorrarDinamico
                          estado={btnAcceso}
                          action={() => dispatch(DeleteImagen(e.img_iden))}
                        />
                      </div>
                      <img
                        style={{ maxWidth: "220px", objectFit: "contain" }}
                        src={e.img_desc}
                        alt="Imagen de Intervencion"
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </Container>
    </Container>
  );
};
