import { MainLayout } from "./Layout";
import { Login } from "./Pages";
import { MainRoutes } from "./Routes";
import { useSelector } from "react-redux";

function App() {

  const serverEstado = useSelector(state => state.Login.server)
  const loginUser = useSelector(state => state.Login.loginUser)

  return serverEstado === false || loginUser === "" ? <Login/> : (
    <MainLayout>
      <MainRoutes />
    </MainLayout>
  );
}

export default App;
