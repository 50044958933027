import React, { useState } from "react";
import { Container, Table, Pagination } from "react-bootstrap";
import { ModalDinamico } from "../Modals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

export const TablaEquiposMensuales = ({ data }) => {
  const PAGE_SIZE = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * PAGE_SIZE;
  const indexOfFirstItem = indexOfLastItem - PAGE_SIZE;
  const currentData = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "800px",
        overflowY: "auto",
        marginTop: "20px",
      }}
    >
      <Table>
        <thead
          style={{
            backgroundColor: "#fed8",
            fontStyle: "italic",
            fontWeight: "bold",
            fontSize: ".8rem",
          }}
        >
          <tr>
            <td>Fecha</td>
            <td>Equipo</td>
            <td>Usuario</td>
            <td>Detalles</td>
          </tr>
        </thead>
        <tbody>
          {currentData.map((e) => (
            <React.Fragment key={e.IntIden}>
              <tr>
                <td style={{ fontSize: ".7rem" }}>
                  <span>{e.IntFech}</span>
                </td>
                <td style={{ fontSize: ".8rem" }}>
                  <strong>{e.EqNume}</strong>
                </td>
                <td style={{ fontSize: ".8rem" }}>{e.Usuario}</td>
                <td>
                  <ModalDinamico
                    nameBoton={<FontAwesomeIcon icon={faEye} />}
                    Body={e.IntDesc}
                    size={"sm"}
                    variant={"outline-dark"}
                    Titulo={"Descripcion Intervencion"}
                  />
                </td>
              </tr>
              <tr style={{ fontSize: ".7rem", backgroundColor: "#ddd" }}>
                <td>{e.Empresa}</td>
                <td>{e.Planta}</td>
                <td>{e.Zona}</td>
                <td>{e.Sector}</td>
              </tr>
              <tr
                style={{ textAlign: "center", borderBottom: "5px solid #999" }}
              >
                <td colSpan={4}>
                  <textarea
                    defaultValue={e.IntAdic}
                    readOnly
                    style={{
                      height: "50px",
                      width: "80vw",
                      maxWidth: "700px",
                      overflowY: "auto",
                      resize: "none",
                      textAlign: "center",
                      border: "none",
                    }}
                  />
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>

      <Pagination style={{ display: "flex", flexWrap: "wrap" }}>
        {Array.from({ length: Math.ceil(data.length / PAGE_SIZE) }).map(
          (item, index) => (
            <Pagination.Item
              key={index + 1}
              active={index + 1 === currentPage}
              onClick={() => paginate(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          )
        )}
      </Pagination>
    </Container>
  );
};
