import React from "react";
import { Formik, Form } from "formik";
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { AddTipoEquipo } from "../../Api/TipoEquipo.Api";
import { useNavigate } from "react-router-dom";

export const FormAddTipoEquipo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Container>
      <Formik
        initialValues={{
          te_desc: "",
        }}
        onSubmit={(values) => {
          Swal.fire({
            title: "Agregar Tipo Equipo?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Agregar !",
          }).then((result) => {
            if (result.isConfirmed) {
              (async (_) => {
                dispatch(AddTipoEquipo(values)).then((res) => {
                  if (res.meta.requestStatus === "fulfilled") {
                    Swal.fire({
                      icon: "success",
                      text: "Tipo Equipo Agregado",
                      toast: true,
                      position: "top-end",
                      timer: 2800,
                      showConfirmButton: false,
                    });
                    navigate("/TipoEquipos");
                  } else {
                    Swal.fire({
                      icon: "info",
                      title: "Respuesta del Servidor",
                      text: "Vuelve a Iniciar sessión, si persiste el problema contáctanos",
                    });
                  }
                });
              })();
            }
          });
        }}
      >
        {({ handleChange }) => (
          <Form
            style={{ display: "flex", flexDirection: "column", gap: "8px" }}
          >
            <input
              type="text"
              name="te_desc"
              placeholder="Nombre Tipo Equipo"
              onChange={handleChange}
              required
            />
            <div>
              <button type="submit">Agregar</button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
