import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "../vGlobal.js"

/* Listar usuarios */
export const GetUsuarios = createAsyncThunk(
"GetUsuarios",
async ()=>{
  try {
    const res = await axios.get(`${url}/Api/Usuarios`);
    const data = res.data;
    return data
  } catch (error) {
    throw new Error(error)
  }
})

export const ValidarCredenciales = createAsyncThunk(
  "CheckCredenciales",
  async ({ currentUser }) => {
    try {
      const res = await axios.post(`${url}/Api/Validar`, currentUser);
      return res.statusText;
    } catch (error) {
      throw new Error(error);
    }
  }
);

/* Agregar usuarios */
export const AddUsuario = createAsyncThunk(
"AddUsuario",
async (values)=>{
  try {
    const res = await axios.post(`${url}/Api/Usuario`, values);
    const data = res.data
    return {us_iden: data.insertId, ...values};
  } catch (error) {
    throw new Error(error)
  }
})

/* Borrar usuarios */
export const DeleteUsuario = createAsyncThunk(
"DeleteUsuario",
async (id)=>{
  try {
    await axios.delete(`${url}/Api/Usuario/${id}`);
    return id;
  } catch (error) {
    throw new Error(error)
  }
})

/* Editar usuarios */
export const EditarUsuario = createAsyncThunk(
  "EditarUsuario",
  async ({values, id}) => {
    try {
      await axios.put(`${url}/Api/Usuario/${id}`, values);
      return {us_iden: id, ...values};
    } catch (error) {
      throw new Error(error);
    }
  }
);


