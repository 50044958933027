import { createSlice} from "@reduxjs/toolkit";
import { GetStadoServer } from "../../Api/GetStadoServer.Api";

export const LoginSlice = createSlice({
  name: "Login",
  initialState: {
    server: false,
    loginUser: "",
    loginRol: "",
    btnAcceso: "",
    error: "",
  },
  reducers: {
      setUser: (state, action)=> {
        state.loginUser = action.payload
      },
      setRol: (state, action)=> {
        state.loginRol = action.payload
      },
      setStadoServer:(state, action)=>{
        state.server = action.payload
      },
      setAccesoBtn: (state, action)=>{
        state.btnAcceso = action.payload
      }
  },
  extraReducers: (builder) => {
    builder
      /* Estado Server */
      .addCase(GetStadoServer.pending, (state, action) => {
        state.server = false;
      })
      .addCase(GetStadoServer.fulfilled, (state, action) => {
        state.server = true;
      })
      .addCase(GetStadoServer.rejected, (state, action) => {
        state.error = action.error;
      })
  },
  
});

export const {setUser, setRol, setStadoServer, setAccesoBtn} = LoginSlice.actions;
export default LoginSlice.reducer;
