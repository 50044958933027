import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "../vGlobal.js";

/* Listar Clientes */
export const GetEmpresas = createAsyncThunk(
  "GetEmpresas",
  async () => {
    try {
      const res = await axios.get(`${url}/Api/Empresas`);
      return res.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

/* Agregar Cliente */
export const AddEmpresa = createAsyncThunk(
  "AddEmpresa",
  async (values) => {
    try {
      const res = await axios.post(`${url}/Api/Empresa`, values);
      const data = res.data
      return {em_iden: data.insertId, ...values};
    } catch (error) {
      throw new Error(error);
    }
  }
);

/* Borrar Cliente */
export const DeleteEmpresa = createAsyncThunk(
  "DeleteEmpresa",
  async (id) => {
    try {
      await axios.delete(`${url}/Api/Empresa/${id}`);
      return id;
    } catch (error) {
      throw new Error(error);
    }
  }
);

/* Editar Cliente */
export const EditarEmpresa = createAsyncThunk(
  "EditarEmpresa",
  async ({values, id}) => {
    try {
      await axios.put(`${url}/Api/Empresa/${id}`, values);
      return {em_iden: id, ...values};
    } catch (error) {
      throw new Error(error);
    }
  }
);

