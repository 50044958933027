import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { TablaZonas } from "../Components/Tablas";
import { useSelector } from "react-redux";
import { BtnVolverDinamico } from "../Components/Botones";
import { ModalDinamico } from "../Components/Modals";
import { FormAddZona } from "../Components/Forms_Add";
import { useNavigate, useParams } from "react-router-dom";
import { NotFound } from "../Pages/NotFound";
import { filterData, findName } from "../globalFunctions";

export const Zonas = () => {
  const dataPlantas = useSelector((state) => state.Plantas.data);
  const dataZonas = useSelector((state) => state.Zonas.data);
  const { idEm, idPl } = useParams();
  const [valueSearch, setValueSearch] = useState("");
  const [dataInit, setDataInit] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const zoByPl = filterData(dataZonas, "pl_iden", parseInt(idPl));
    valueSearch === ""
      ? setDataInit(zoByPl)
      : setDataInit(filterData(zoByPl, "zo_desc", valueSearch));
  }, [valueSearch, dataZonas, idPl]);

  return (
    <Container style={{ maxWidth: "900px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          marginTop:"5px"
        }}
      >
        <div>
          <BtnVolverDinamico
            click={() => {
              navigate(`/Empresa/${idEm}/Plantas`);
            }}
          />
        </div>
        <div
          style={{
            textAlign: "center",
          }}
        >
          <div
            style={{ fontStyle: "italic", textAlign: "center", padding: "5px" }}
          >
            <span>Zonas de la Planta:</span>
            <b style={{ display: "block", color: "#46be6a" }}>
              {findName(dataPlantas, "pl_iden", parseInt(idPl), "pl_desc")}
            </b>
          </div>

          <ModalDinamico
            size={"sm"}
            variant={"outline-dark"}
            Body={<FormAddZona idEm={idEm} idPl={idPl} />}
            Titulo="Agregar Zona"
            nameBoton="Agregar Zona"
          />
        </div>
      </div>

      <hr />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <span style={{ fontStyle: "italic" }}>Buscar </span>
        <input
          type="text"
          placeholder="Nombre Zona"
          onChange={(e) => {
            setValueSearch(e.target.value);
          }}
        />
      </div>

      <div>
        {dataInit.length === 0 ? (
          <NotFound textoImagen={"Ninguna Zona Registrada"} />
        ) : (
          <TablaZonas data={dataInit} />
        )}
      </div>
    </Container>
  );
};
