import React from "react";
import { GetEmpresas } from "../../Api/Empresas.Api";
import { GetPlantas } from "../../Api/Plantas.Api";
import { GetZonas } from "../../Api/Zonas.Api";
import { GetSectores } from "../../Api/Sectores.Api";
import { GetEquipos } from "../../Api/Equipos.Api";
import { GetIntervenciones } from "../../Api/Intervenciones.Api";
import { GetMarcas } from "../../Api/Marcas.Api";
import { GetRoles } from "../../Api/Roles.Api";
import { GetTipoEquipos } from "../../Api/TipoEquipo.Api";
import { GetImagenes } from "../../Api/Imagenes.Api";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";

export const BtnUpdateDatabase = () => {
  const dispatch = useDispatch();

  const handleEventClick = () => {
    Swal.fire({
      title: "Actualizando...",
      toast: false,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });

    dispatch(GetEmpresas());
    dispatch(GetEquipos());
    dispatch(GetIntervenciones());
    dispatch(GetMarcas());
    dispatch(GetPlantas());
    dispatch(GetRoles());
    dispatch(GetSectores());
    dispatch(GetTipoEquipos());
    dispatch(GetZonas());
    dispatch(GetImagenes());
  };

  return (
    <Button size="sm" variant="outline-dark" onClick={handleEventClick}>
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span>Update</span>
        <FontAwesomeIcon icon={faRotate} size="sm" />
      </div>
    </Button>
  );
};
