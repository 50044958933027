import React from "react";
import { Formik, Form } from "formik";
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { AddEmpresa } from "../../Api/Empresas.Api";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

export const FormAddEmpresa = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Container>
      <Formik
        initialValues={{
          em_name: "",
          em_email: "",
          em_cuit: "",
          em_tele: "",
        }}
        validationSchema={Yup.object({
          em_email: Yup.string().email(
            "Ingrese una dirección de correo electrónico válida"
          ),
          em_cuit: Yup.string().matches(
            /^\d{11}$/,
            "El CUIT debe contener exactamente 11 dígitos"
          ),
        })}
        onSubmit={(values) => {
          const newEmpresa = {
            em_name: values.em_name,
            em_email: values.em_email,
            em_cuit: parseInt(values.em_cuit),
            em_tele: values.em_tele,
          };
          Swal.fire({
            title: "Agregar Empresa?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Agregar!",
          }).then((result) => {
            if (result.isConfirmed) {
              (async (_) => {
                dispatch(AddEmpresa(newEmpresa)).then((res) => {
                  switch (res.meta.requestStatus) {
                    case "fulfilled":
                      Swal.fire({
                        icon: "success",
                        toast: true,
                        position: "top-end",
                        text: "Empresa Agregada",
                        timer: 2800,
                        showConfirmButton: false,
                      });
                      navigate("/Empresas");
                      break;
                    default:
                      Swal.fire({
                        icon: "info",
                        title: "Respuesta del Servidor",
                        text: "Vuelve a Iniciar sessión, si persiste el problema contáctanos",
                      });
                      break;
                  }
                });
              })();
            }
          });
        }}
      >
        {({ errors, handleChange }) => (
          <Form
            style={{ display: "flex", flexDirection: "column", gap: "8px" }}
          >
            <input
              type="text"
              name="em_name"
              placeholder="Nombre Completo"
              onChange={handleChange}
              required
            />
            <input
              itype="email"
              name="em_email"
              placeholder="Email@algo.com"
              onChange={handleChange}
              required
            />
            {errors.em_email && (
              <div style={{ color: "#e11414" }}>{errors.em_email}</div>
            )}
            <input
              type="number"
              name="em_cuit"
              placeholder="Cuit"
              onChange={handleChange}
              required
            />
            {errors.em_cuit && (
              <div style={{ color: "#e11414" }}>{errors.em_cuit}</div>
            )}
            <input
              type="number"
              name="em_tele"
              placeholder="Telefono"
              onChange={handleChange}
              required
            />
            <div>
              <button type="submit">Agregar</button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
