import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { EditarUsuario } from "../../Api/Usuarios.Api";
import { useNavigate } from "react-router-dom";
import { Loader } from "../Principales";
import * as Yup from "yup";

export const FormEditUsuario = ({ id }) => {
  const [usById, setUsById] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = useSelector((state) => state.Usuarios.data);
  const dataRoles = useSelector((state) => state.Roles.data);

  useEffect(() => {
    const res = data.filter((e) => e.us_iden === id);
    setUsById(res[0]);
  }, [id, data]);

  const { us_name, us_pass, ro_iden, us_iden } = usById;

  return Object.keys(usById).length === 0 ? (
    <Loader />
  ) : (
    <Container>
      <Formik
        initialValues={{
          us_iden: us_iden,
          us_name: us_name,
          us_pass: us_pass,
          ro_iden: ro_iden,
          confirmPassword: "",
        }}
        validationSchema={Yup.object({
          us_pass: Yup.string().min(6, "Debe Contener al menos 6 Caracteres"),
          confirmPassword: Yup.string().oneOf(
            [Yup.ref("us_pass"), null],
            "Las contraseñas deben Coincidir"
          ),
        })}
        onSubmit={(values) => {
          console.log(values);
          Swal.fire({
            title: "Editar Usuario ?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Editar !",
          }).then((result) => {
            if (result.isConfirmed) {
              (async (_) => {
                dispatch(EditarUsuario({ values, id })).then((res) => {
                  if (res.meta.requestStatus === "fulfilled") {
                    Swal.fire({
                      icon: "success",
                      text: "Usuario Editado",
                      toast: true,
                      position: "top-end",
                      timer: 2800,
                      showConfirmButton: false,
                    });
                    navigate("/Usuarios");
                  } else {
                    Swal.fire({
                      icon: "info",
                      title: "Respuesta del Servidor",
                      text: "Vuelve a Iniciar sessión, si persiste el problema contáctanos",
                    });
                  }
                });
              })();
            }
          });
        }}
      >
        {({ handleChange, errors, values }) => (
          <Form
            style={{ display: "flex", flexDirection: "column", gap: "8px" }}
          >
            <label>Modifica Nombre:</label>
            <input
              type="text"
              name="us_name"
              value={values.us_name}
              onChange={handleChange}
            />
            <label>Modifica Password:</label>
            <input
              type="password"
              name="us_pass"
              value={values.us_pass}
              onChange={handleChange}
              required
            />
            {errors.us_pass && (
              <div style={{ color: "#e11414" }}>{errors.us_pass}</div>
            )}
            {values.us_pass && (
              <>
                <label>Confirma Password:</label>
                <Field
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirmar Password"
                  required
                />
                {errors.confirmPassword && (
                  <div style={{ color: "#e11414" }}>
                    {errors.confirmPassword}
                  </div>
                )}
              </>
            )}
            <label>Modifica Rol:</label>
            <Field
              as="select"
              type="number"
              name="ro_iden"
              value={values.ro_iden}
              onChange={handleChange}
              required
            >
              <option></option>
              {dataRoles.map((e) => (
                <option key={e.ro_iden} value={e.ro_iden}>
                  {e.ro_desc}
                </option>
              ))}
            </Field>
            <div>
              <button type="submit">Editar</button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
