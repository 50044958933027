import React from "react";
import { Formik, Form } from "formik";
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { AddPlanta } from "../../Api/Plantas.Api";
import { useNavigate } from "react-router-dom";

export const FormAddPlanta = ({ idEmp }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dataEmpresas = useSelector((state) => state.Empresas.data);

  return (
    <Container>
      <Formik
        initialValues={{
          em_iden: parseInt(idEmp),
          pl_desc: "",
        }}
        onSubmit={(values) => {
          Swal.fire({
            title: "Agregar Planta?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Agregar!",
          }).then((result) => {
            if (result.isConfirmed) {
              (async (_) => {
                dispatch(AddPlanta(values)).then((res) => {
                  switch (res.meta.requestStatus) {
                    case "fulfilled":
                      Swal.fire({
                        icon: "success",
                        toast: true,
                        position: "top-end",
                        timer: 2800,
                        text: "Planta Agregada",
                        showConfirmButton: false,
                      });
                      navigate(`/Empresa/${idEmp}/Plantas`);
                      break;
                    default:
                      Swal.fire({
                        icon: "info",
                        title: "Respuesta del Servidor",
                        text: "Vuelve a Iniciar sessión, si persiste el problema contáctanos",
                      });
                      break;
                  }
                });
              })();
            }
          });
        }}
      >
        {({ handleChange }) => (
          <Form
            style={{ display: "flex", flexDirection: "column", gap: "8px" }}
          >
            <input
              type="number"
              name="em_iden"
              readOnly
              placeholder={(() => {
                const resEm = dataEmpresas.filter(
                  (e) => e.em_iden === parseInt(idEmp)
                );
                if (resEm.lenght !== 0) {
                  return resEm[0].em_name;
                } else {
                  return "";
                }
              })()}
              onChange={handleChange}
            />
            <label>Descripcion:</label>
            <input
              type="text"
              name="pl_desc"
              placeholder="Nombre Planta"
              onChange={handleChange}
              required
            />
            <div>
              <button type="submit">Agregar</button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
