import React from "react";
import { Formik, Field, Form } from "formik";
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddUsuario } from "../../Api/Usuarios.Api";
import * as Yup from "yup";

export const FormAddUsuario = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dataRoles = useSelector((state) => state.Roles.data);

  return (
    <Container>
      <Formik
        initialValues={{
          us_name: "",
          us_pass: "",
          ro_iden: "",
          confirmPassword: "",
        }}
        validationSchema={Yup.object({
          us_pass: Yup.string().min(6, "Debe Contener al menos 6 Caracteres"),
          confirmPassword: Yup.string().oneOf(
            [Yup.ref("us_pass"), null],
            "Las contraseñas deben Coincidir"
          ),
        })}
        onSubmit={(values) => {
          Swal.fire({
            title: "Agregar Usuario ?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Agregar !",
          }).then((result) => {
            if (result.isConfirmed) {
              const newUser = {
                us_name: values.us_name,
                us_pass: values.us_pass,
                ro_iden: parseInt(values.ro_iden),
              };
              (async (_) => {
                dispatch(AddUsuario(newUser)).then((res) => {
                  if (res.meta.requestStatus === "fulfilled") {
                    Swal.fire({
                      icon: "success",
                      text: "Usuario Agregado",
                      toast: true,
                      position: "top-end",
                      timer: 2800,
                      showConfirmButton: false,
                    });
                    navigate("/Usuarios");
                  } else {
                    Swal.fire({
                      icon: "info",
                      title: "Respuesta del Servidor",
                      text: "Vuelve a Iniciar sessión, si persiste el problema contáctanos",
                    });
                  }
                });
              })();
            }
          });
        }}
      >
        {({ values, errors, handleChange }) => (
          <Form
            style={{ display: "flex", flexDirection: "column", gap: "8px" }}
          >
            <input
              type="text"
              name="us_name"
              placeholder="Nombre Usuario"
              onChange={handleChange}
              required
            />

            <input
              type="password"
              name="us_pass"
              placeholder="Nuevo Password"
              onChange={handleChange}
              required
            />
            {errors.us_pass && (
              <div style={{ color: "#e11414" }}>{errors.us_pass}</div>
            )}
            {values.us_pass && (
              <>
                <label>Confirma Password:</label>
                <Field
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirmar Password"
                  required
                />
                {errors.confirmPassword && (
                  <div style={{ color: "#e11414" }}>
                    {errors.confirmPassword}
                  </div>
                )}
              </>
            )}

            <label>Seleccione un Rol: </label>
            <Field
              as="select"
              type="text"
              name="ro_iden"
              onChange={handleChange}
              required
            >
              <option></option>
              {dataRoles.map((e) => (
                <option key={e.ro_iden} value={e.ro_iden}>
                  {e.ro_desc}
                </option>
              ))}
            </Field>
            <div>
              <button type="submit">Agregar</button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
