import { createSlice } from "@reduxjs/toolkit";
import { GetTipoEquipos, AddTipoEquipo, DeleteTipoEquipo, EditarTipoEquipo } from "../../Api/TipoEquipo.Api.js";

export const TipoEquiposSlice = createSlice({
    name:"TipoEquipos",
    initialState: {
        data: [],
        error:  null
    },
    reducers: {

    },
    extraReducers:(builder)=>{
        builder
        /* Listar Tipo Equipos */
      .addCase(GetTipoEquipos.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(GetTipoEquipos.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(GetTipoEquipos.rejected, (state, action) => {
        state.error = action.payload;
      })
      /* Add Tipo Equipo */
      .addCase(AddTipoEquipo.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(AddTipoEquipo.fulfilled, (state, action) => {
        state.data.push(action.payload);
      })
      .addCase(AddTipoEquipo.rejected, (state, action) => {
        state.error = action.payload;
      })
      /* Delete Tipo Equipo */
      .addCase(DeleteTipoEquipo.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(DeleteTipoEquipo.fulfilled, (state, action) => {
        state.data = state.data.filter((e) => e.te_iden !== action.payload);
      })
      .addCase(DeleteTipoEquipo.rejected, (state, action) => {
        state.error = action.payload;
      })
      /* Editar Tipo Equipo */
      .addCase(EditarTipoEquipo.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(EditarTipoEquipo.fulfilled, (state, action) => {
        const index = state.data.findIndex(
          (e) => e.te_iden === action.payload.te_iden
        );
        if (index !== -1) {
          state.data[index] = action.payload;
        }
      })
      .addCase(EditarTipoEquipo.rejected, (state, action) => {
        state.error = action.payload;
      });
    }
});

export default TipoEquiposSlice.reducer