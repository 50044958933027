import React from "react";
import { Formik, Form } from "formik";
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { AddSector } from "../../Api/Sectores.Api";
import { useNavigate } from "react-router-dom";

export const FormAddSector = ({ idEm, idPl, idZo }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dataEmpresas = useSelector((state) => state.Empresas.data);
  const dataPlantas = useSelector((state) => state.Plantas.data);
  const dataZonas = useSelector((state) => state.Zonas.data);

  return (
    <Container>
      <Formik
        initialValues={{
          se_desc: "",
          em_iden: parseInt(idEm),
          pl_iden: parseInt(idPl),
          zo_iden: parseInt(idZo),
        }}
        onSubmit={(values) => {
          Swal.fire({
            title: "Agregar Sector?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Agregar!",
          }).then((result) => {
            if (result.isConfirmed) {
              (async (_) => {
                dispatch(AddSector(values)).then((res) => {
                  switch (res.meta.requestStatus) {
                    case "fulfilled":
                      Swal.fire({
                        icon: "success",
                        toast: true,
                        position: "top-end",
                        timer: 2800,
                        text: "Sector Agregado",
                        showConfirmButton: false,
                      });
                      navigate(
                        `/Empresa/${idEm}/Planta/${idPl}/Zona/${idZo}/Sectores`
                      );
                      break;
                    default:
                      Swal.fire({
                        icon: "info",
                        title: "Respuesta del Servidor",
                        text: "Vuelve a Iniciar sessión, si persiste el problema contáctanos",
                      });
                      break;
                  }
                });
              })();
            }
          });
        }}
      >
        {({ handleChange }) => (
          <Form
            style={{ display: "flex", flexDirection: "column", gap: "8px" }}
          >
            <input
              type="number"
              name="em_iden"
              readOnly
              placeholder={(() => {
                const res = dataEmpresas.filter(
                  (e) => e.em_iden === parseInt(idEm)
                );
                if (res.lenght !== 0) {
                  return res[0].em_name;
                } else {
                  return "";
                }
              })()}
            />
            <input
              type="number"
              name="pl_iden"
              readOnly
              placeholder={(() => {
                const res = dataPlantas.filter(
                  (e) => e.pl_iden === parseInt(idPl)
                );
                if (res.lenght !== 0) {
                  return res[0].pl_desc;
                } else {
                  return "";
                }
              })()}
            />
            <input
              type="number"
              name="zo_desc"
              readOnly
              placeholder={(() => {
                const res = dataZonas.filter(
                  (e) => e.zo_iden === parseInt(idZo)
                );
                if (res.lenght !== 0) {
                  return res[0].zo_desc;
                } else {
                  return "";
                }
              })()}
            />
            <label>Descripcion:</label>
            <input
              type="text"
              name="se_desc"
              onChange={handleChange}
              placeholder="Nombre Sector"
              required
            />

            <div>
              <button type="submit">Agregar</button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
