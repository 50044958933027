import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Administracion,
  Empresas,
  Home,
  Equipos,
  Intervenciones,
  Marcas,
  NotFound,
  Plantas,
  Sectores,
  TipoEquipo,
  Usuarios,
  Zonas,
  IntervencionDetalle,
  ReporteEquiposMensuales,
} from "../Pages";
import { Header } from "../Components/Principales";

export const MainRoutes = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        {/* Rutas Generales */}
        <Route exact path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
        <Route exact path="/Empresas" element={<Empresas />} />

        {/* Estas rutas son las del Administrador */}
        <Route exact path="/Administracion" element={<Administracion />} />
        <Route exact path="/Usuarios" element={<Usuarios />} />
        <Route exact path="/Marcas" element={<Marcas />} />
        <Route exact path="/TipoEquipos" element={<TipoEquipo />} />

        <Route
          exact
          path="/ReporteEquiposmensuales"
          element={<ReporteEquiposMensuales />}
        />

        {/* Estas Rutas son las de Empresa que sirven para Agregar campos */}
        <Route exact path="/Empresa/:idEm/Plantas" element={<Plantas />} />
        <Route
          exact
          path="/Empresa/:idEm/Planta/:idPl/Zonas"
          element={<Zonas />}
        />
        <Route
          exact
          path="/Empresa/:idEm/Planta/:idPl/Zona/:idZo/Sectores"
          element={<Sectores />}
        />
        <Route
          exact
          path="/Empresa/:idEm/Planta/:idPl/Zona/:idZo/Sector/:idSe/Equipos"
          element={<Equipos />}
        />
        <Route
          exact
          path="/Empresa/:idEm/Planta/:idPl/Zona/:idZo/Sector/:idSe/Equipo/:idEq/Intervenciones"
          element={<Intervenciones />}
        />
        <Route
          exact
          path="/Equipo/:idEq/Intervencion/:idInt"
          element={<IntervencionDetalle />}
        />
      </Routes>
    </BrowserRouter>
  );
};
