import { createSlice } from "@reduxjs/toolkit";
import { GetZonas, AddZona, DeleteZona, EditarZona } from "../../Api/Zonas.Api";

export const ZonasSlice = createSlice({
  name: "Zonas",
  initialState: {
    data: [],
    error: "",
  },
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      /* Listar Zona */
      .addCase(GetZonas.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(GetZonas.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(GetZonas.rejected, (state, action) => {
        state.error = action.error;
      })
      /* Add Zona */
      .addCase(AddZona.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(AddZona.fulfilled, (state, action) => {
        state.data.push(action.payload);
      })
      .addCase(AddZona.rejected, (state, action) => {
        state.error = action.error;
        state.data = [];
      })
      /* Delete Zona */
      .addCase(DeleteZona.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(DeleteZona.fulfilled, (state, action) => {
        state.data = state.data.filter(e => e.zo_iden !== action.payload);
      })
      .addCase(DeleteZona.rejected, (state, action) => {
        state.error = action.error;
      })
      /* Editar Zona */
      .addCase(EditarZona.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(EditarZona.fulfilled, (state, action) => {
        const index = state.data.findIndex(e => e.zo_iden === action.payload.zo_iden)
        if(index !== -1){
          state.data[index] = action.payload
        }
      })
      .addCase(EditarZona.rejected, (state, action) => {
        state.error = action.error;
      })
  },
});

export default ZonasSlice.reducer;