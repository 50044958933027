import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "../vGlobal.js";

/* Listar Equipos */
export const GetEquipos = createAsyncThunk(
  "GetEquipos",
  async () => {
    try {
      const res = await axios.get(`${url}/Api/Equipos`);
      return res.data;
    } catch (error) {
      return error;
    }
  }
);

/* Agregar Equipo */
export const AddEquipo = createAsyncThunk(
  "AddEquipo",
  async (values) => {
    try {
      const res = await axios.post(`${url}/Api/Equipo`, values);
      const data = res.data
      return {eq_iden: data.insertId, ...values};
    } catch (error) {
      throw new Error(error);
    }
  }
);

/* Borrar Equipo */
export const DeleteEquipo = createAsyncThunk(
  "DeleteEquipo",
  async (id) => {
    try {
      await axios.delete(`${url}/Api/Equipo/${id}`);
      return id;
    } catch (error) {
      throw new Error(error);
    }
  }
);

/* Editar Equipo */
export const EditarEquipo = createAsyncThunk(
  "EditarEquipo",
  async ({ eqEditado, id }) => {
    try {
      await axios.put(`${url}/Api/Equipo/${id}`, eqEditado);
      return { eq_iden: id, ...eqEditado };
    } catch (error) {
      throw new Error(error);
    }
  }
);