import React from "react";
import gifNotFound from "../img/404.gif";

export const NotFound = ({ textoImagen }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: "35px",
        alignItems: "center",
        gap: "20px",
      }}
    >
      <div style={{ fontSize: "1.2rem", fontStyle: "oblique", opacity: "50%" }}>
        <strong>{textoImagen}</strong>
      </div>
      <img
        src={gifNotFound}
        alt="Gif 404"
        style={{ width: "290px", opacity: "50%" }}
      />
    </div>
  );
};
