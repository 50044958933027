import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { TablaEquipos } from "../Components/Tablas";
import { useSelector } from "react-redux";
import { BtnVolverDinamico } from "../Components/Botones";
import { ModalDinamico } from "../Components/Modals";
import { FormAddEquipo } from "../Components/Forms_Add";
import { useNavigate, useParams } from "react-router-dom";
import { NotFound } from "../Pages/NotFound";
import { filterData, findName } from "../globalFunctions";

export const Equipos = () => {
  const dataEquipos = useSelector((state) => state.Equipos.data);
  const dataSectores = useSelector((state) => state.Sectores.data);
  const { idEm, idPl, idZo, idSe } = useParams();
  const [dataInit, setDataInit] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setDataInit(filterData(dataEquipos, "se_iden", parseInt(idSe)));
  }, [dataEquipos, idSe]);

  return (
    <Container style={{ maxWidth: "900px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          marginTop:"5px"
        }}
      >
        <div>
          <BtnVolverDinamico
            click={() => {
              navigate(`/Empresa/${idEm}/Planta/${idPl}/Zona/${idZo}/Sectores`);
            }}
          />
        </div>
        <div
          style={{
            textAlign: "center",
          }}
        >
          <div
            style={{ fontStyle: "italic", textAlign: "center", padding: "5px" }}
          >
            <span>Equipos del Sector:</span>
            <b style={{ display: "block", color: "#46be6a" }}>
              {findName(dataSectores, "se_iden", parseInt(idSe), "se_desc")}
            </b>
          </div>

          <ModalDinamico
            size={"sm"}
            variant={"outline-dark"}
            Body={
              <FormAddEquipo idEm={idEm} idPl={idPl} idZo={idZo} idSe={idSe} />
            }
            Titulo="Agregar Equipo"
            nameBoton="Agregar Equipo"
          />
        </div>
      </div>

      <hr />
      <div>
        {dataInit.length === 0 ? (
          <NotFound textoImagen={"Ningun Equipo Registrado"} />
        ) : (
          <TablaEquipos data={dataInit} />
        )}
      </div>
    </Container>
  );
};
