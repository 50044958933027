import { createSlice } from "@reduxjs/toolkit";
import { GetEquipos, AddEquipo, DeleteEquipo, EditarEquipo } from "../../Api/Equipos.Api";

export const EquiposSlice = createSlice({
  name: "Equipos",
  initialState: {
    data: [],
    error: null,
  },
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      /* Listar Equipo */
      .addCase(GetEquipos.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(GetEquipos.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(GetEquipos.rejected, (state, action) => {
        state.error = action.error;
      })
      /* Add Equipo */
      .addCase(AddEquipo.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(AddEquipo.fulfilled, (state, action) => {
        state.data.push(action.payload);
      })
      .addCase(AddEquipo.rejected, (state, action) => {
        state.error = action.error;
        state.data = [];
      })
      /* Delete Equipo */
      .addCase(DeleteEquipo.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(DeleteEquipo.fulfilled, (state, action) => {
        state.data = state.data.filter(e => e.eq_iden !== action.payload);
      })
      .addCase(DeleteEquipo.rejected, (state, action) => {
        state.error = action.error;
      })
      /* Editar Equipo */
      .addCase(EditarEquipo.pending, (state, action) => {
        state.error = action.payload;
      })
      .addCase(EditarEquipo.fulfilled, (state, action) => {
        const index = state.data.findIndex(e => e.eq_iden === action.payload.eq_iden)
        if(index !== -1){
          state.data[index] = action.payload
        }
      })
      .addCase(EditarEquipo.rejected, (state, action) => {
        state.error = action.error;
      })
  },
});

export default EquiposSlice.reducer;
