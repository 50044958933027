import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { GetUsuarios } from "../Api/Usuarios.Api";
import { GetRoles } from "../Api/Roles.Api";
import { GetStadoServer } from "../Api/GetStadoServer.Api";
import { setUser, setRol } from "../Store/Slices/LoginSlice";
import { Footer } from "../Components/Principales";
import { ValidarCredenciales } from "../Api/Usuarios.Api";
import Swal from "sweetalert2";
import { url } from "../vGlobal";
import axios from "axios";

export const Login = () => {
  const serverEstado = useSelector((state) => state.Login.server);
  const dataUsuarios = useSelector((state) => state.Usuarios.data);
  const dataRoles = useSelector((state) => state.Roles.data);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetStadoServer());
    dispatch(GetUsuarios());
    dispatch(GetRoles()).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        const parseUser = JSON.parse(localStorage.getItem("userStorage"));
        axios
          .post(`${url}/Api/ValidarStorage`, parseUser)
          .then((res) => {
            if (res.status === 200) {
              dispatch(setUser(parseUser.us_name));
              dispatch(setRol(parseUser.ro_iden));
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              icon: "info",
              toast: true,
              position: "top",
              title: "No Pudimos Validarte",
              text: "Ingresa Credenciales",
              showConfirmButton: false,
              timer: 2800,
            });
          });
      }
    });
  }, [dispatch]);

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px",
        gap: "20px",
        marginTop: "30px",
      }}
    >
      <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
        <span>
          <b>Estado del Servidor:</b>
        </span>
        {serverEstado ? (
          <FontAwesomeIcon icon={faThumbsUp} size="lg" color="#46be6a" />
        ) : (
          <FontAwesomeIcon icon={faThumbsDown} size="lg" color="#e11414" />
        )}
      </div>

      <div>
        <h1 style={{textAlign:"center"}}>Iniciar Session</h1>
        <Formik
          initialValues={{ us_name: "", ro_iden: "", us_pass: "" }}
          onSubmit={(values) => {
            const currentUser = {
              us_name: values.us_name,
              ro_iden: parseInt(values.ro_iden),
              us_pass: values.us_pass,
            };
            dispatch(ValidarCredenciales({ currentUser })).then((res) => {
              if (res.meta.requestStatus === "fulfilled") {
                dispatch(setUser(values.us_name));
                dispatch(setRol(parseInt(values.ro_iden)));
                const userStorage = dataUsuarios.filter(
                  (e) => e.us_name === values.us_name
                );
                localStorage.setItem(
                  "userStorage",
                  JSON.stringify(userStorage[0])
                );
              } else {
                return Swal.fire({
                  icon: "error",
                  toast: true,
                  position: "top",
                  title: "Revisa Credenciales",
                  showConfirmButton: false,
                  timer: 2000,
                });
              }
            });
          }}
        >
          {({ handleChange }) => (
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                minWidth: "260px",
              }}
            >
              <label>
                <b>Usuario:</b>
              </label>
              <Field
                style={{ height: "30px" }}
                as="select"
                name="us_name"
                onChange={handleChange}
                required
              >
                <option></option>
                {dataUsuarios.map((e) => (
                  <option key={e.us_iden}>{e.us_name}</option>
                ))}
              </Field>

              <label>
                <b>Rol:</b>
              </label>
              <Field
                style={{ height: "30px" }}
                as="select"
                name="ro_iden"
                onChange={handleChange}
                required
              >
                <option></option>
                {dataRoles.map((e) => (
                  <option key={e.ro_iden} value={e.ro_iden}>
                    {e.ro_desc}
                  </option>
                ))}
              </Field>

              <label>
                <b>Password:</b>
              </label>
              <Field
                type="password"
                name="us_pass"
                onChange={handleChange}
                required
              />

              <ErrorMessage name="us_pass" component="div" />

              <button type="submit">Iniciar</button>
            </Form>
          )}
        </Formik>
      </div>
      <Footer />
    </Container>
  );
};
