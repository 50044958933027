import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";

export const BtnBorrarDinamico = ({ action, estado }) => {
  const handleEventClick = () => {
    Swal.fire({
      title: "Borrar ?",
      text: "No prodrás revertir este cambio!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Borrar !",
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          await action().then((res) => {
            switch (res.meta.requestStatus) {
              case "fulfilled":
                Swal.fire({
                  icon: "success",
                  text: "Eliminado",
                  toast: true,
                  position: "top-end",
                  timer: 2800,
                  showConfirmButton: false,
                });
                break;

              default:
                Swal.fire({
                  icon: "info",
                  title: "Respuesta del Servidor",
                  text: "Es Posible que este valor contenga datos Relacionados",
                });
                break;
            }
          });
        })();
      }
    });
  };

  return (
    <Button
      disabled={estado}
      variant="outline-danger"
      size="sm"
      onClick={handleEventClick}
    >
      <FontAwesomeIcon size="xs" icon={faTrash} />
    </Button>
  );
};
