import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { TablaIntervenciones } from "../Components/Tablas";
import { ModalDinamico } from "../Components/Modals";
import { useNavigate, useParams } from "react-router-dom";
import { FormAddIntervencion } from "../Components/Forms_Add";
import { useSelector } from "react-redux";
import { BtnBoostrapDinamico, BtnVolverDinamico } from "../Components/Botones";
import { findName, filterData, filterByDate } from "../globalFunctions";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { PdfIntByEq } from "../Components/Forms_Reportes";
import { Loader } from "../Components/Principales";
import { NotFound } from "./NotFound";

export const Intervenciones = () => {
  const { idEm, idPl, idZo, idSe, idEq } = useParams();

  const [fechaIni, setFechaIni] = useState("");
  const [fechaDest, setFechaDest] = useState("");

  const dataIntervenciones = useSelector((state) => state.Intervenciones.data);
  const dataEmpresas = useSelector((state) => state.Empresas.data);
  const dataEquipos = useSelector((state) => state.Equipos.data);
  const dataPlantas = useSelector((state) => state.Plantas.data);
  const dataZonas = useSelector((state) => state.Zonas.data);
  const dataSector = useSelector((state) => state.Sectores.data);

  const btnEstado = useSelector((state) => state.Login.btnAcceso);
  const currentUser = useSelector((state) => state.Login.loginRol);

  const [dataInit, setDataInit] = useState([]);
  const [currentEq, setCurrentEq] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    const eqById = {
      Empresa: findName(dataEmpresas, "em_iden", parseInt(idEm), "em_name"),
      Planta: findName(dataPlantas, "pl_iden", parseInt(idPl), "pl_desc"),
      Zona: findName(dataZonas, "zo_iden", parseInt(idZo), "zo_desc"),
      Sector: findName(dataSector, "se_iden", parseInt(idSe), "se_desc"),
      EqNume: findName(dataEquipos, "eq_iden", parseInt(idEq), "eq_nume"),
    };
    setCurrentEq(eqById);
    const intByEq = filterData(dataIntervenciones, "eq_iden", parseInt(idEq));
    fechaIni === "" && fechaDest === ""
      ? setDataInit(intByEq)
      : setDataInit(filterByDate(intByEq, "int_fech", fechaIni, fechaDest));
  }, [fechaIni, fechaDest, dataIntervenciones, idEq]);

  return  (
    <Container style={{ maxWidth: "900px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          marginTop: "5px",
        }}
      >
        <div>
          <BtnVolverDinamico
            click={() =>
              navigate(
                `/Empresa/${idEm}/Planta/${idPl}/Zona/${idZo}/Sector/${idSe}/Equipos`
              )
            }
          />
        </div>
        <div
          style={{
            textAlign: "center",
          }}
        >
          <div
            style={{ fontStyle: "italic", textAlign: "center", padding: "5px" }}
          >
            <span>Empresa:</span>
            <b style={{ display: "block", color: "#46be6a" }}>
              {currentEq.Empresa}
            </b>
            <span>Int del Equipo:</span>
            <b style={{ display: "block", color: "#46be6a" }}>
              {currentEq.EqNume}
            </b>
          </div>
          <ModalDinamico
            size={"sm"}
            variant={"outline-dark"}
            Body={
              <FormAddIntervencion
                idEm={idEm}
                idPl={idPl}
                idZo={idZo}
                idSe={idSe}
                idEq={idEq}
              />
            }
            Titulo="Agregar Intervencion"
            nameBoton="Agregar Intervencion"
            estado={currentUser === 4 ? "" : btnEstado}
          />
        </div>
      </div>
      <hr />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "10px",
          gap: "10px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <div>
            <span style={{ fontStyle: "italic", marginRight: "10px" }}>
              Desde:{" "}
            </span>
            <input
              type="date"
              onChange={(e) => {
                setFechaIni(e.target.value);
              }}
            />
          </div>
          <div>
            <span style={{ fontStyle: "italic", marginRight: "10px" }}>
              Hasta:{" "}
            </span>
            <input
              type="date"
              onChange={(e) => {
                setFechaDest(e.target.value);
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "10px",
            }}
          >
            <PDFDownloadLink
              document={<PdfIntByEq listaInt={dataInit} objEq={currentEq} />}
              fileName={`Intervenciones Equipo ${currentEq.EqNume} ${fechaIni} - ${fechaDest}.pdf`}
            >
              <BtnBoostrapDinamico
                color={"outline-dark"}
                name={<FontAwesomeIcon icon={faDownload} />}
              />
            </PDFDownloadLink>
            <BtnBoostrapDinamico
              name={"Reset"}
              click={() => {
                setFechaIni("");
                setFechaDest("");
              }}
            />
          </div>
        </div>
      </div>
      <div>
        {dataInit.length === 0 ? (
          <NotFound textoImagen={"Ninguna Intervención Encontrada"}/>
        ) : (
          <TablaIntervenciones data={dataInit} />
        )}
      </div>
    </Container>
  );
};
