import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

export const ModalDinamico = ({
  Body,
  Titulo,
  nameBoton,
  estado,
  size,
  variant,
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        disabled={estado}
        size={size}
        variant={variant}
        onClick={handleShow}
      >
        {nameBoton}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{Titulo}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{Body}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
