import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import { EditarEquipo } from "../../Api/Equipos.Api";
import { useNavigate } from "react-router-dom";
import { Loader } from "../Principales";
import { findName } from "../../globalFunctions";

export const FormEditEquipo = ({ id }) => {
  const [eqById, setEqById] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dataEquipos = useSelector((state) => state.Equipos.data);
  const dataEmpresas = useSelector((state) => state.Empresas.data);
  const dataPlantas = useSelector((state) => state.Plantas.data);
  const dataZonas = useSelector((state) => state.Zonas.data);
  const dataSectores = useSelector((state) => state.Sectores.data);
  const dataTipoEquipo = useSelector((state) => state.TipoEquipos.data);
  const dataMarca = useSelector((state) => state.Marcas.data);

  useEffect(() => {
    const res = dataEquipos.filter((e) => e.eq_iden === id);
    setEqById(res[0]);
  }, [id, dataEquipos]);

  const {
    em_iden,
    pl_iden,
    zo_iden,
    se_iden,
    te_iden,
    ma_iden,
    eq_nume,
    eq_frig,
  } = eqById;

  return Object.keys(eqById).length === 0 ? (
    <Loader />
  ) : (
    <Container>
      <Formik
        initialValues={{
          eq_nume: eq_nume,
          eq_frig: eq_frig,

          em_iden: em_iden,
          pl_iden: pl_iden,
          zo_iden: zo_iden,
          se_iden: se_iden,

          ma_iden: ma_iden,
          te_iden: te_iden,
        }}
        onSubmit={(values) => {
          Swal.fire({
            title: "Editar Este Equipo?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Editar !",
          }).then((result) => {
            if (result.isConfirmed) {
              const eqEditado = {
                eq_nume: values.eq_nume,
                eq_frig: values.eq_frig,
                
                em_iden: values.em_iden,
                pl_iden: values.pl_iden,
                zo_iden: values.zo_iden,
                se_iden: values.se_iden,
                
                ma_iden: parseInt(values.ma_iden),
                te_iden: parseInt(values.te_iden),
              };
              (async (_) => {
                dispatch(EditarEquipo({ id, eqEditado })).then((res) => {
                  if (res.meta.requestStatus === "fulfilled") {
                    Swal.fire({
                      icon: "success",
                      text: "Equipo Editado",
                      toast: true,
                      position: "top-end",
                      timer: 2800,
                      showConfirmButton: false,
                    });
                    navigate(
                      `/Empresa/${em_iden}/Planta/${pl_iden}/Zona/${zo_iden}/Sector/${se_iden}/Equipos`
                    );
                  } else {
                    Swal.fire({
                      icon: "info",
                      title: "Respuesta del Servidor",
                      text: "Vuelve a Iniciar sessión, si persiste el problema contáctanos",
                    });
                  }
                });
              })();
            }
          });
        }}
      >
        {({ handleChange }) => (
          <Form
            style={{ display: "flex", flexDirection: "column", gap: "8px" }}
          >
            <input
              type="number"
              name="em_iden"
              placeholder={findName(
                dataEmpresas,
                "em_iden",
                em_iden,
                "em_name"
              )}
              readOnly
            />
            <input
              type="number"
              name="pl_iden"
              placeholder={findName(dataPlantas, "pl_iden", pl_iden, "pl_desc")}
              readOnly
            />
            <input
              type="number"
              name="zo_iden"
              placeholder={findName(dataZonas, "zo_iden", zo_iden, "zo_desc")}
              readOnly
            />
            <input
              type="number"
              name="se_iden"
              placeholder={findName(
                dataSectores,
                "se_iden",
                se_iden,
                "se_desc"
              )}
              readOnly
            />

            <input
              type="number"
              name="eq_nume"
              placeholder={eq_nume}
              readOnly
            />

            <label>Marca</label>
            <Field
              name="ma_iden"
              as="select"
              type="number"
              onChange={handleChange}
              required
            >
              <option></option>
              {dataMarca.map((e) => (
                <option key={e.ma_iden} value={e.ma_iden}>
                  {e.ma_desc}
                </option>
              ))}
            </Field>

            <label>Tipo Equipo</label>
            <Field
              name="te_iden"
              as="select"
              type="number"
              onChange={handleChange}
              required
            >
              <option></option>
              {dataTipoEquipo.map((e) => (
                <option key={e.te_iden} value={e.te_iden}>
                  {e.te_desc}
                </option>
              ))}
            </Field>

            <input
              type="number"
              name="eq_frig"
              placeholder={eq_frig}
              onChange={handleChange}
              required
            />

            <div>
              <button type="submit">Editar</button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
