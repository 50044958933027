import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { EditarSector } from "../../Api/Sectores.Api";
import { useNavigate } from "react-router-dom";
import { Loader } from "../Principales";
import { findName } from "../../globalFunctions";

export const FormEditSector = ({ id }) => {
  const [seById, setSeById] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dataSectores = useSelector((state) => state.Sectores.data);
  const dataEmpresas = useSelector((state) => state.Empresas.data);
  const dataPlantas = useSelector((state) => state.Plantas.data);
  const dataZonas = useSelector((state) => state.Zonas.data);

  useEffect(() => {
    const res = dataSectores.filter((e) => e.se_iden === id);
    setSeById(res[0]);
  }, [id, dataSectores]);

  const { se_iden, se_desc, em_iden, zo_iden, pl_iden } = seById;

  return Object.keys(seById).length === 0 ? (
    <Loader />
  ) : (
    <Container>
      <Formik
        initialValues={{
          em_iden: em_iden,
          pl_iden: pl_iden,
          zo_iden: zo_iden,
          se_iden: se_iden,
          se_desc: se_desc,
        }}
        onSubmit={(values) => {
          Swal.fire({
            title: "Editar Sector ?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Editar !",
          }).then((result) => {
            if (result.isConfirmed) {
              (async (_) => {
                dispatch(EditarSector({ id, values })).then((res) => {
                  if (res.meta.requestStatus === "fulfilled") {
                    Swal.fire({
                      icon: "success",
                      text: "Sector Editado",
                      toast: true,
                      position: "top-end",
                      timer: 2800,

                      showConfirmButton: false,
                    });
                    navigate(
                      `/Empresa/${em_iden}/Planta/${pl_iden}/Zona/${zo_iden}/Sectores`
                    );
                  } else {
                    Swal.fire({
                      icon: "info",
                      title: "Respuesta del Servidor",
                      text: "Vuelve a Iniciar sessión, si persiste el problema contáctanos",
                    });
                  }
                });
              })();
            }
          });
        }}
      >
        {({ handleChange }) => (
          <Form
            style={{ display: "flex", flexDirection: "column", gap: "8px" }}
          >
            <label>Empresa:</label>
            <input
              type="number"
              name="em_iden"
              placeholder={findName(
                dataEmpresas,
                "em_iden",
                em_iden,
                "em_name"
              )}
              readOnly
            />
            <label>Planta:</label>
            <input
              type="number"
              name="pl_iden"
              placeholder={findName(dataPlantas, "pl_iden", pl_iden, "pl_desc")}
              readOnly
            />
            <label>Zona:</label>
            <input
              type="number"
              name="zo_iden"
              placeholder={findName(dataZonas, "zo_iden", zo_iden, "zo_desc")}
              readOnly
            />

            <label>Modifica Descripcion:</label>
            <input
              type="text"
              name="se_desc"
              placeholder={se_desc}
              onChange={handleChange}
              required
            />

            <div>
              <button type="submit">Editar</button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
