import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "../vGlobal.js"

/* Listar Tipo Equipo */
export const GetTipoEquipos = createAsyncThunk(
  "GetTipoEquipos",
  async () => {
    try {
      const res = await axios.get(`${url}/Api/TipoEquipos`);
      return res.data;
    } catch (error) {
      return error;
    }
  }
);

/* Agregar Tipo Equipo */
export const AddTipoEquipo = createAsyncThunk(
  "AddTipoEquipo",
  async (values) => {
    try {
      const res = await axios.post(`${url}/Api/TipoEquipo`, values);
      const data = res.data
      return {te_iden: data.insertId, ...values};
    } catch (error) {
      return error;
    }
  }
);

/* Borrar Tipo Equipo */
export const DeleteTipoEquipo = createAsyncThunk(
  "DeleteTipoEquipo",
  async (id) => {
    try {
      await axios.delete(`${url}/Api/TipoEquipo/${id}`);
      return id;
    } catch (error) {
      return error;
    }
  }
);

/* Editar Tipo Equipo */
export const EditarTipoEquipo = createAsyncThunk(
  "EditarTipoEquipo",
  async ({values, id}) => {
    try {
      await axios.put(`${url}/Api/TipoEquipo/${id}`, values);
      return {te_iden: id, ...values};
    } catch (error) {
      return error;
    }
  }
);

