import React from "react";
import deWebLogo from "../../img/Logo250px.png";

export const Footer = () => {
  return (
    <div
      style={{ marginTop: "60px", marginBottom: "30px", textAlign: "center" }}
    >
      <a href="https://dewebdesarrollos.com" target="blank">
        <img
          style={{ width: "70%", opacity: "50%" }}
          src={deWebLogo}
          alt="Logo Deweb"
        />
      </a>
    </div>
  );
};
