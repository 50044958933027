import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { EditarIntervencion } from "../../Api/Intervenciones.Api";
import { useNavigate } from "react-router-dom";
import { findName, findObject } from "../../globalFunctions";

export const FormEditIntervencion = ({ id, idEq }) => {
  const [currentInt, setCurrentInt] = useState({});
  const [currentEq, setCurrentEq] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dataIntervenciones = useSelector((state) => state.Intervenciones.data);
  const dataUsuarios = useSelector((state) => state.Usuarios.data);
  const dataEquipos = useSelector((state) => state.Equipos.data);

  useEffect(() => {
    const intById = findObject(dataIntervenciones, "int_iden", id);
    setCurrentInt(intById);
    const eqById = findObject(dataEquipos, "eq_iden", idEq);
    setCurrentEq(eqById);
  }, [id, idEq, dataIntervenciones, dataEquipos]);

  return (
    <Container>
      <Formik
        initialValues={{
          int_fech: currentInt.int_iden,
          int_desc: currentInt.int_desc,
          us_iden: currentInt.us_iden,
          eq_iden: currentInt.eq_iden,
          int_adic_desc: currentInt.int_adic_desc,
        }}
        onSubmit={(values) => {
          Swal.fire({
            title: "Editar Intervencion?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Editar!",
          }).then((result) => {
            if (result.isConfirmed) {
              (async (_) => {
                dispatch(EditarIntervencion({ id, values })).then((res) => {
                  switch (res.meta.requestStatus) {
                    case "fulfilled":
                      Swal.fire({
                        icon: "success",
                        text: "Intervencion Editada",
                        toast: true,
                        position: "top-end",
                        timer: 2800,
                        showConfirmButton: false,
                      });
                      navigate("/");
                      break;
                    default:
                      Swal.fire({
                        icon: "info",
                        title: "Respuesta del Servidor",
                        text: "Vuelve a Iniciar sessión, si persiste el problema contáctanos",
                      });
                      break;
                  }
                });
              })();
            }
          });
        }}
      >
        {({ handleChange }) => (
          <Form
            style={{ display: "flex", flexDirection: "column", gap: "8px" }}
          >
            <input
              type="text"
              name="int_fech"
              placeholder={currentInt.int_fech}
              readOnly
            />
            <input
              type="number"
              name="us_iden"
              placeholder={findName(
                dataUsuarios,
                "us_iden",
                currentInt.us_iden,
                "us_name"
              )}
              readOnly
            />
            <input
              type="number"
              name="eq_iden"
              placeholder={findName(
                dataEquipos,
                "eq_iden",
                currentInt.eq_iden,
                "eq_nume"
              )}
              readOnly
            />

            <label>Modifica Descripcion:</label>
            <textarea
              style={{ height: "100px", padding: "5px" }}
              type="text"
              name="int_desc"
              defaultValue={currentInt.int_desc}
              onChange={handleChange}
              required
            />

            <label>Modifica Adicionales:</label>
            <textarea
              style={{ height: "100px", padding: "5px" }}
              type="text"
              name="int_adic_desc"
              defaultValue={currentInt.int_adic_desc}
              onChange={handleChange}
              required
            />
            <div>
              <button type="submit">Editar</button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
